import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUserGet } from '../../../types/Interface';
import { getItemLS, getItemObjLS, getItemStrLS, setItemStrLS, getTeachersLS } from '../../../utils/getUserLS';
import { PAY_VALUE_DEFAULT } from '../../../constants/payValue';


export interface UsersStateFull {
  teachers: IUserGet[];
	teachersNum: any;
	tempTag: string;
	tempSearchNum: any;
	tempSearchArr: any;
	students: IUserGet[];
	studentsNum: number[];
	direction: any;
	directionNum: any;
	studentsNumPay: any;
  tempStudents: any;
  tempStudentsNum: Array<number>;
  sub: any;
  subNum: any;
  sheetsDate: any;
  valueCash: number;
}

export interface UsersState {
  usersRed: IUserGet[],
}
export interface InvitesState {
	invitesRed: IUserGet[],
}

const initialState: UsersStateFull = {
  teachers: getTeachersLS(),
	teachersNum: getItemLS('teachersNum'),
	tempTag: '',
	tempSearchNum: [],
	tempSearchArr: [],
	students: getItemLS('students'),
	studentsNum: getItemLS('studentsNum'),
	direction:getItemLS('direction'),
	directionNum: getItemLS('directionNum'),
	studentsNumPay: getItemObjLS('studentsNumPay'),
  tempStudents: getItemLS('tempStudents'),
  tempStudentsNum: getItemLS('tempStudentsNum'),
  sub: getItemLS('sub'),
  subNum: getItemLS('subNum'),
  sheetsDate: (new Date).toISOString(),
  valueCash: Number(getItemStrLS('valueCash')),
}

export const sheetsSlice = createSlice({
  name: 'sheets',
  initialState,
  reducers: {
		setTempSearchArr: (state, action: PayloadAction<IUserGet[]>) => {
			// console.log('payload',action.payload)
			// console.log('state',state.usersRed)
			state.tempSearchArr = action.payload
			// console.log('mas',mas)

			// state.usersRed.concat(action.payload.usersRed)
			// state.usersRed = [...mas ]
			// state.usersRed
			// Object.assign(state.usersR, action.payload);
			// console.log('state22',state.usersRed)
			// state.usersR.concat(action.payload)
		},
		resTeacher: (state) => {
			state.teachers = []
			state.teachersNum = []
			// console.log('state33',state.invitesRed)
			const jsonR = JSON.stringify(state.teachersNum)
			localStorage.setItem('teachersNum',jsonR)
			const jsonRR = JSON.stringify(state.teachers)
			localStorage.setItem('teachers',jsonRR)
		},
		resDirection: (state) => {
			state.direction = []
			state.directionNum = []
			// console.log('state33',state.invitesRed)
			const jsonR = JSON.stringify(state.directionNum)
			localStorage.setItem('directionNum',jsonR)
			const jsonRR = JSON.stringify(state.direction)
			localStorage.setItem('direction',jsonRR)
		},
		resStudents: (state) => {
			state.students = []
			state.studentsNum = []
			state.studentsNumPay = {}
			// console.log('state33',state.invitesRed)
			const jsonR = JSON.stringify(state.studentsNum)
			localStorage.setItem('studentsNum',jsonR)
			const jsonRR = JSON.stringify(state.students)
			localStorage.setItem('students',jsonRR)
			const jsonRRR = JSON.stringify(state.studentsNumPay)
			localStorage.setItem('studentsNumPay',jsonRRR)


      state.tempStudents = []
			state.tempStudentsNum = []
      const jsonStu = JSON.stringify(state.tempStudents)
			localStorage.setItem('tempStudents',jsonStu)
			const jsonStu2 = JSON.stringify(state.tempStudentsNum)
			localStorage.setItem('tempStudentsNum',jsonStu2)
		},
    resTempStudent: (state) => {
      state.tempStudents = []
			state.tempStudentsNum = []
      const jsonStu = JSON.stringify(state.tempStudents)
			localStorage.setItem('tempStudents',jsonStu)
			const jsonStu2 = JSON.stringify(state.tempStudentsNum)
			localStorage.setItem('tempStudentsNum',jsonStu2)
    },
    resSub: (state) => {
      state.sub = []
			state.subNum = []
      const jsonStu = JSON.stringify(state.sub)
			localStorage.setItem('sub',jsonStu)
			const jsonStu2 = JSON.stringify(state.subNum)
			localStorage.setItem('subNum',jsonStu2)

      // state.tempStudents = []
			// state.tempStudentsNum = []
      // const jsonStu3 = JSON.stringify(state.tempStudents)
			// localStorage.setItem('tempStudents',jsonStu3)
			// const jsonStu33 = JSON.stringify(state.tempStudentsNum)
			// localStorage.setItem('tempStudentsNum',jsonStu33)

      state.tempSearchNum = []
      const jsonRR = JSON.stringify(state.tempSearchNum)
			localStorage.setItem('tempSearchNum',jsonRR)
    },
    resTempSearchNum: (state) => {
      state.tempSearchNum = []
      const jsonRR = JSON.stringify(state.tempSearchNum)
			localStorage.setItem('tempSearchNum',jsonRR)
    },
		setTempSearchNum: (state, action: PayloadAction<number>) => {
			// state.invitesMasRed = action.payload
			// console.log('setr',action.payload)
			if (state.tempSearchNum.includes(action.payload)) {
				state.tempSearchNum = state.tempSearchNum.filter((id: number) => id != action.payload)
			} else {
				state.tempSearchNum = [...state.tempSearchNum, action.payload]
			}
			const jsonRR = JSON.stringify(state.tempSearchNum)
			localStorage.setItem('tempSearchNum',jsonRR)
		},
		setTempSearchNumArr: (state, action: PayloadAction<any>) =>{
			state.tempSearchNum = action.payload
		},
		setTeachers: (state) => {
			const arr = state.tempSearchArr.filter((item:any) => {
				return state.tempSearchNum.includes(Number(item.number))
			})
			state.teachers = arr
			const jsonR = JSON.stringify(state.teachers)
			localStorage.setItem('teachers',jsonR)
			state.teachersNum = state.tempSearchNum
			const jsonRR = JSON.stringify(state.teachersNum)
			localStorage.setItem('teachersNum',jsonRR)
		},
		setTeachersNum: (state, action: PayloadAction<any>) => {

			// state.teachersNum = action.payload

			// state.invitesMasRed = action.payload
			// console.log('setr',action.payload)
			if (state.teachersNum.includes(action.payload)) {
				state.teachersNum = state.teachersNum.filter((id: number) => id != action.payload)
			} else {
				state.teachersNum = [...state.teachersNum, action.payload]
			}
			const jsonRR = JSON.stringify(state.teachersNum)
			localStorage.setItem('teachersNum',jsonRR)
		},
		setStudents: (state) => {

			state.tempSearchNum.filter((itemTemp: any) => {
				if (!state.studentsNum.includes(itemTemp)) {


					state.studentsNumPay[`${itemTemp}-stuNumPay`] = {
						...state.studentsNumPay[`${itemTemp}-stuNumPay`],
						type: 'абн',
						value: 'ожд!',
						id: itemTemp,
					}
					const jsonRRNum = JSON.stringify(state.studentsNumPay)
					localStorage.setItem('studentsNumPay',jsonRRNum)


				}
			})

      const arr: Array<any> = []
      // const arr2: Array<any> = []
      state.tempSearchNum.map((tempNum: number) => {
        // console.log('state.tempSearchArr',state.tempSearchArr[tempNum - 1])
        // console.log('state.tempSearchArr-num',tempNum)
        if(state.tempSearchArr[tempNum - 1].id === tempNum) {
          arr.push(state.tempSearchArr[tempNum - 1])
        }
      })
      // console.log("arr2", arr2)
      // state.tempSearchArr.map((item:any) => {
      //   if(state.tempSearchNum.includes(Number(item.number))){
      //     console.log('item', item)
      //     arr.push(item)
      //   }
      // })
			// const arr: Array<any> = state.tempSearchArr.filter((item:any) => {
        
			// 	return state.tempSearchNum.includes(Number(item.number))
			// })

			state.students = arr
			const jsonR = JSON.stringify(state.students)
			localStorage.setItem('students',jsonR)
			state.studentsNum = state.tempSearchNum
			const jsonRR = JSON.stringify(state.studentsNum)
			localStorage.setItem('studentsNum',jsonRR)
		},
		setStudentsNum: (state, action: PayloadAction<number>) => {
			// state.invitesMasRed = action.payload
			// console.log('setr',action.payload)
			if (state.studentsNum.includes(action.payload)) {
				state.studentsNum = state.studentsNum.filter((id: number) => id != action.payload)
			} else {
				state.studentsNum = [...state.studentsNum, action.payload]
			}
			const jsonRR = JSON.stringify(state.studentsNum)
			localStorage.setItem('studentsNum',jsonRR)
		},
    setAddStudents: (state, action: PayloadAction<any>) => {
      const obj = {
        fio: action.payload,
        number: (1000 + state.tempStudentsNum.length),
        id: (1000 + state.tempStudentsNum.length)
      }

      state.tempStudents = [...state.tempStudents, obj]
      state.tempStudentsNum = [...state.tempStudentsNum, obj.number]


			const jsonR = JSON.stringify(state.tempStudents)
			localStorage.setItem('tempStudents',jsonR)
			const jsonRR = JSON.stringify(state.tempStudentsNum)
			localStorage.setItem('tempStudentsNum',jsonRR)


      state.studentsNumPay[`${ obj.number}-stuNumPay`] = {
        ...state.studentsNumPay[`${ obj.number}-stuNumPay`],
        type: `${PAY_VALUE_DEFAULT}б/н`,
        value: `${PAY_VALUE_DEFAULT}б/н`,
        id:  obj.number,
      }
      const jsonRRNum = JSON.stringify(state.studentsNumPay)
      localStorage.setItem('studentsNumPay',jsonRRNum)

    },
		setDirection: (state) => {
			const arr = state.tempSearchArr.filter((item:any) => {
				return state.tempSearchNum.includes(Number(item.number))
			})
			state.direction = arr
			const jsonR = JSON.stringify(state.direction)
			localStorage.setItem('direction',jsonR)
			state.directionNum = state.tempSearchNum
			const jsonRR = JSON.stringify(state.directionNum)
			localStorage.setItem('directionNum',jsonRR)
		},
		setDirectionNum: (state, action: PayloadAction<number>) => {
			// state.invitesMasRed = action.payload
			// console.log('setr',action.payload)
			if (state.directionNum.includes(action.payload)) {
				state.directionNum = state.directionNum.filter((id: number) => id != action.payload)
			} else {
				state.directionNum = [...state.directionNum, action.payload]
			}
			const jsonRR = JSON.stringify(state.directionNum)
			localStorage.setItem('directionNum',jsonRR)
		},
		setStudentsNumPayType: (state, actions: PayloadAction<any>) => {
			// console.log(actions.payload.id)
			// console.log(actions.payload.value)
			state.studentsNumPay[`${actions.payload.id}-stuNumPay`] = {
				...state.studentsNumPay[`${actions.payload.id}-stuNumPay`],
				type: actions.payload.value,
				id: actions.payload.id,
			}
			const jsonRR = JSON.stringify(state.studentsNumPay)
			localStorage.setItem('studentsNumPay',jsonRR)
		},
		setStudentsNumPayValue: (state, actions: PayloadAction<any>) => {
			// console.log(actions.payload.id)
			// console.log(actions.payload.value)
			state.studentsNumPay[`${actions.payload.id}-stuNumPay`] = {
				...state.studentsNumPay[`${actions.payload.id}-stuNumPay`],
				value: actions.payload.value,
				id: actions.payload.id,
			}
			const jsonRR = JSON.stringify(state.studentsNumPay)
			localStorage.setItem('studentsNumPay',jsonRR)
		},
    setStudentsNumPayCheck: (state, actions: PayloadAction<any>) => {
      let check: boolean
      if (state.studentsNumPay[`${actions.payload.id}-stuNumPay`].check) {
        check = !state.studentsNumPay[`${actions.payload.id}-stuNumPay`].check
      } else {
        check = true
      }

			state.studentsNumPay[`${actions.payload.id}-stuNumPay`] = {
				...state.studentsNumPay[`${actions.payload.id}-stuNumPay`],
        check
			}
			const jsonRR = JSON.stringify(state.studentsNumPay)
			localStorage.setItem('studentsNumPay',jsonRR)
		},
		setStudentsNumPayBalance: (state, actions: PayloadAction<any>) => {
			// console.log(actions.payload.id)
			// console.log(actions.payload.value)
			state.studentsNumPay[`${actions.payload.id}-stuNumPay`] = {
				...state.studentsNumPay[`${actions.payload.id}-stuNumPay`],
				balanceNew: actions.payload.balanceNew,
				id: actions.payload.id,
			}
			const jsonRR = JSON.stringify(state.studentsNumPay)
			localStorage.setItem('studentsNumPay',jsonRR)
		},
    setSub: (state) => {
			const arr = state.tempSearchArr.filter((item:any) => {
				return state.tempSearchNum.includes(Number(item.number))
			})
			state.sub = arr
			const jsonR = JSON.stringify(state.sub)
			localStorage.setItem('sub',jsonR)
			state.subNum = state.tempSearchNum
			const jsonRR = JSON.stringify(state.subNum)
			localStorage.setItem('subNum',jsonRR)
		},
		// setSubNum: (state, action: PayloadAction<any>) => {

		// 	if (state.teachersNum.includes(action.payload)) {
		// 		state.teachersNum = state.teachersNum.filter((id: number) => id != action.payload)
		// 	} else {
		// 		state.teachersNum = [...state.teachersNum, action.payload]
		// 	}
		// 	const jsonRR = JSON.stringify(state.teachersNum)
		// 	localStorage.setItem('teachersNum',jsonRR)
		// },
    setSheetsDate: (state, action: PayloadAction<any>) => {
      // console.log(action.payload)
      state.sheetsDate = action.payload
    },
    setValueCash: (state, action: PayloadAction<any>) => {
      state.valueCash = action.payload
      const jsonRR = JSON.stringify(state.valueCash)
			setItemStrLS('valueCash',jsonRR)
    }
  },
})

export const { setTempSearchArr,
	resTeacher,
	resDirection,
	resStudents,
  resTempStudent,
  resSub,
  resTempSearchNum,
	setTempSearchNum,
	setTeachers,
	setTeachersNum,
	setStudents,
	setStudentsNum,
  setAddStudents,
	setDirection,
	setDirectionNum,
	setTempSearchNumArr,
	setStudentsNumPayType,
	setStudentsNumPayValue,
  setStudentsNumPayCheck,
  setStudentsNumPayBalance,
  setSub,
  // setSubNum,
  setSheetsDate,
  setValueCash,
} = sheetsSlice.actions

export default sheetsSlice.reducer
