import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setStudentsNumPayBalance, setStudentsNumPayCheck, setStudentsNumPayType, setStudentsNumPayValue } from '../../redux/slices/sheetsSlice/sheetsSlice';
import './RowOnlyStu.scss';
import { PAY_VALUE_DEFAULT, PayValue } from '../../constants/payValue';
import LatinaController from '../../API/LatinaController';

interface RowOnlyStuProps {
	student?: any;
	// student?: IUserJSON | null;
	nameRow?: string;
	number?: string | number;
	tagSearch?: string;
  index?: any
}

const RowOnlyStu: FC<RowOnlyStuProps> = ({
	student, nameRow, number, tagSearch, index
}) => {

  const ind = index + 1; 
  // console.log(ind)

		const isMountedR = React.useRef(false)

	const studentsNumPay = useAppSelector((state) => state.sheets.studentsNumPay)
  const directionNum = useAppSelector((state) => state.sheets.directionNum)
  const token = useAppSelector((state) => state.usersR.token)
  const sheetsDate = useAppSelector((state) => state.sheets.sheetsDate)
	const dispatch = useAppDispatch()

  const priceSub = process.env.REACT_APP_PRICE_SUB ? Number(process.env.REACT_APP_PRICE_SUB) : 0
  const priceFire = process.env.REACT_APP_PRICE_FIRE ? Number(process.env.REACT_APP_PRICE_FIRE) : 0
  const priceHoreo = process.env.REACT_APP_PRICE_HOREO ? Number(process.env.REACT_APP_PRICE_HOREO) : 0

		useEffect(()=> {
		if (!isMountedR.current) {
			// const input = (document.getElementById(`${student.number}-stuPayInput`) as HTMLInputElement)
			// console.log('mount',input)
			// input.value = tempValue;
      let tempType: any
      let tempValue: any
      let tempBalance: any
      if(studentsNumPay[`${student.number}-stuNumPay`]) {
        tempType = studentsNumPay[`${student.number}-stuNumPay`].type
        tempValue = studentsNumPay[`${student.number}-stuNumPay`].value
        tempBalance = studentsNumPay[`${student.number}-stuNumPay`].balanceNew
    
        const input = (document.getElementById(`${student.number}-stuPayInput`) as HTMLInputElement)
        // console.log('input',input)
        if(input && tempValue) {
          // console.log('hfp',tempValue)
          input.value = tempValue;
        }
      }
		}
		isMountedR.current = true
	}, [studentsNumPay])

	let tempType: any
	let tempValue: any
	let tempBalance: any
	if(studentsNumPay[`${student.number}-stuNumPay`]) {
		tempType = studentsNumPay[`${student.number}-stuNumPay`].type
		tempValue = studentsNumPay[`${student.number}-stuNumPay`].value
		tempBalance = studentsNumPay[`${student.number}-stuNumPay`].balanceNew

		const input = (document.getElementById(`${student.number}-stuPayInput`) as HTMLInputElement)
		// console.log('input',input)
    if(input && tempValue) {
			// console.log('hfp',tempValue)
			input.value = tempValue;
		}
	}
	if (!tempType) {
		tempType = 'абн';
	}
	if (!tempValue) {
		tempValue = '';
	}
	if (!tempBalance) {
		tempBalance = '';
	}

	// console.log(tempType, tempValue)

	let nameTwo:any
	let nameOne:any

	switch(tagSearch){
		case 'direction':
			nameTwo = student.name;
			nameOne = student.number;
			break
		default:
      if(student?.info) {
        const infoStu = JSON.parse(student?.info)
        nameTwo = infoStu.info
      } else {
        nameTwo = student["fio"]
      }
      nameOne = student["number"]
	}

  const subSC = async(arr:any) => {
    const prom: any = []
    for (let i=0; i<arr.length; i++) {
      prom[i] = await fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${arr[i]}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Token ${token}`,
        },
      })
      .then((res) => res.json())
      .then((dataSC) => {
        return dataSC
      })
    }
    return await Promise.all([...prom])
  }

  // Выбор абонементов в зависимости от направления. Также в Teacher
  const funSubscriptExp = async ({direction, id}: { direction?: number, id?: number}) => {
    let stuSub
    direction = directionNum[0]
    switch(direction) {
      // case 33:
      //   break;
      // case 34:
      //   break;

      // #LA: АфроДэнсхол 
      // case 18:
      //   try {
      //     stuSub = await LatinaController.studentObjSubSpez({id:1})
      //   } catch (e: any) {
      //     console.log(e)
      //   }
      //   await funSubscrib(stuSub, id)
      //   break;

      // #LA: Лэди Таррашо
      // case 19:
      //   try {
      //     stuSub = await LatinaController.studentObjSubSpez({id:1})
      //   } catch (e: any) {
      //     console.log(e)
      //   }
      //   await funSubscrib(stuSub, id)
      //   break;

      // #LU: Хорео-класс
      case 44:
        try {
          stuSub = await LatinaController.studentObjSubSpez({id:1})
        } catch (e: any) {
          console.log(e)
        }
        await funSubscrib(stuSub, id)
        break;

      default:
        try {
          stuSub = await LatinaController.studentObjSubNotSpez({id:1})
        } catch (e: any) {
          console.log(e)
        }
        await funSubscrib(stuSub, id)
        break
    }
  }


	const funSubscrib = async (stuSub: any, val: any) => {

    if (val < 1000) {
		// // for (let val of tempSearchNum) {
		// 	// const dataF = fetch(`http://localhost:8000/api/student/${val}/`, {
		// 	const dataF = fetch(`${process.env.REACT_APP_DOM}/api/student/${val}/`, {
		// 		method: 'GET',
		// 		headers: {
		// 			'Content-Type': 'application/json; charset=UTF-8',
    //       'Authorization': `Token ${token}`,
		// 		},
		// 	})
		// 	.then((res) => res.json())
		// 	.then((data) => {
    //     if (data.subscrip) {

    //       let abn: Array<any>

    //       const arr =  data.subscrip.split(',') as Array<any>

    //       if(arr.length === 1) {
    //         // abn = [...arr]
    //         abn = arr.map(val => val.slice(1))
    //       } else {
    //         const abnFlam = (arr.filter(val => {
    //           return val[0] === 'B'
    //         }).map(val => val.slice(1)))
    //         const abnNoFlam = (arr.filter(val => {
    //           return val[0] !== 'B'
    //         }).map(val => val.slice(1)))
    //         abn = [...abnFlam, ...abnNoFlam]
    //       }

    //       subSC(abn).then(res => {
    //         let kontrol = false
    //         for (val of res) {
    //           if(directionNum[0] == '33' || directionNum[0] == '34' ) {
    //             if(val.price == 2500) {
    //             const infoAbn = JSON.parse(val.info)

    //             const oldDate = new Date(Date.parse(val.pub_date))

    //             let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
    //             let newDate2 = new Date(newDate.setDate(newDate.getDate()+1))

    //             const oneDate = new Date(sheetsDate)

    //             if (oneDate.getTime() > newDate2.getTime()) {

    //               val.status = false
    //             } else {
    //               if (infoAbn.dateBonusParty) {
    //                 if (val.balance > 0) {
    //                   const id = Number(val.id_users);
    //                   const value = val.item
    //                   const quantity = val.quantity
    //                   const balanceNew = quantity - (val.balance - 1)
    //                   // console.log('teacher-dataFF', val, balanceNew)
    //                   dispatch(setStudentsNumPayType({id, value: 'абн'}))
    //                   dispatch(setStudentsNumPayValue({id, value}))
    //                   dispatch(setStudentsNumPayBalance({id, balanceNew}))
    //                   kontrol = true
    //                   break
    //                 }
    //               } else {
    //                 infoAbn.dateBonusParty = [sheetsDate]
    //                 const id = Number(val.id_users);
    //                 const value = val.item
    //                 // console.log('teacher2-dataFF', val, value)
    //                 dispatch(setStudentsNumPayType({id, value: 'абн'}))
    //                 dispatch(setStudentsNumPayValue({id,value}))
    //                 dispatch(setStudentsNumPayBalance({id, balanceNew: 'бон'}))
    //                 kontrol = true
    //                 break
    //               }
    //             }
                  
    //             } else {
    //               if (val.balance > 0) {
    //                 const id = Number(val.id_users);
    //                 const value = val.item
    //                 const quantity = val.quantity
    //                 const balanceNew = quantity - (val.balance - 1)
    //                 // console.log('teacher3-dataFF', val, balanceNew)
    //                 dispatch(setStudentsNumPayType({id, value: 'абн'}))
    //                 dispatch(setStudentsNumPayValue({id, value}))
    //                 dispatch(setStudentsNumPayBalance({id, balanceNew}))
    //                 kontrol = true
    //                 break
    //               }
    //             }
    //           } else {
    //             if(val.price == 2500) {
    //               const infoAbn = JSON.parse(val.info)
  
    //               const oldDate = new Date(Date.parse(val.pub_date))
  
    //               let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
    //               let newDate2 = new Date(newDate.setDate(newDate.getDate()+1))
  
    //               const oneDate = new Date(sheetsDate)
    //               // console.log('oneDate999',oneDate)
                  
    //               if (oneDate.getTime() > newDate2.getTime()) {
  
    //                 val.status = false
    //               } else {
    //                 if (val.balance > 0) {
    //                     // console.log('dfgfd4546', val)
    //                     const id = Number(val.id_users);
    //                     const value = val.item
    //                     const quantity = val.quantity
    //                     const balanceNew = quantity - (val.balance - 1)
    //                     // console.log('teacher4-dataFF', val, balanceNew, value)
    //                     dispatch(setStudentsNumPayType({id, value: 'абн'}))
    //                     dispatch(setStudentsNumPayValue({id, value}))
    //                     dispatch(setStudentsNumPayBalance({id, balanceNew}))
    //                     kontrol = true
    //                     break
    //                   }
    //               }
                    
    //               } else {
    //                 if (val.balance > 0) {
    //                   const id = Number(val.id_users);
    //                   const value = val.item
    //                   const quantity = val.quantity
    //                   const balanceNew = quantity - (val.balance - 1)
    //                   // console.log('teacher5-dataFF', val, balanceNew)
    //                   dispatch(setStudentsNumPayType({id, value: 'абн'}))
    //                   dispatch(setStudentsNumPayValue({id, value}))
    //                   dispatch(setStudentsNumPayBalance({id, balanceNew}))
    //                   kontrol = true
    //                   break
    //                 }
    //               }
    //           }
    //         }

    //         if (kontrol === false) {
    //           const id = data.id_users
    //           dispatch(setStudentsNumPayType({id, value: 'абн'}))
    //           dispatch(setStudentsNumPayValue({id, value: 'нет'}))
    //           dispatch(setStudentsNumPayBalance({id, balanceNew: ''}))
    //         }
    //       })


		// 		} else {
		// 			const id = data.id_users
    //       if(!studentsNumPay[`${id}-stuNumPay`] ||  studentsNumPay[`${id}-stuNumPay`].type == 'абн') {
            
    //         // console.log('teacher6-dataFF', id )
    //         dispatch(setStudentsNumPayType({id, value: 'абн'}))
    //         dispatch(setStudentsNumPayValue({id, value: 'нет'}))
    //         dispatch(setStudentsNumPayBalance({id, balanceNew: ''}))
    //       }
		// 		}


		// 	})
		// 	.catch((err) => console.log(err))
		// 	.finally(() => {});

		// // }

    // } else {
    //   dispatch(setStudentsNumPayType({id: val, value: 'абн'}))
    //   dispatch(setStudentsNumPayValue({id: val, value: 'нет'}))
    //   dispatch(setStudentsNumPayBalance({id: val, balanceNew: ''}))
    // }


    const num = val
    // const stuSub = await LatinaController.studentObjSubAll({id:1})
    console.log('stuSub', stuSub)
    // for (let num of tempSearchNum) {

      console.log('val', num)
      console.log('stuSub[val]', stuSub[num])
      // const dataF = fetch(`http://${process.env.REACT_APP_DOM}/api/student/${val}/`, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json; charset=UTF-8',
      //     'Authorization': `Token ${token}`,
      //   },
      // })
      // .then((res) => res.json())
      // .then((data) => {
        
      // })
      // .catch((err) => console.log(err))
      // .finally(() => {});

      if (stuSub[num].subscriptionsList) {

        let abn: Array<any>

        const arr =  stuSub[num].subscriptions as Array<any>
        
        // if(arr.length === 1) {
        //   // abn = [...arr]
        //   abn = arr.map(val => val.slice(1))
        // } else {
        //   const abnFlam = (arr.filter(val => {
        //     return val[0] === 'B'
        //   }).map(val => val.slice(1)))
        //   const abnNoFlam = (arr.filter(val => {
        //     return val[0] !== 'B'
        //   }).map(val => val.slice(1)))
        //   abn = [...abnFlam, ...abnNoFlam]
        // }

        // subSC(abn).then(res => {

        // Алгоритм выбора абонемента (чуть отличается от teacher)
          let kontrol = false
          for (let val of arr) {
            if(directionNum[0] == '33' || directionNum[0] == '34' ) {
              if(val.isFire) {
              const infoAbn = JSON.parse(val.info)

              const oldDate = new Date(Date.parse(val.dateBegin))

              let dateKorrekt = 1
              if(process.env.REACT_APP_KORREKT_DATE) {
                dateKorrekt = Number(process.env.REACT_APP_KORREKT_DATE)
              }

              let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
              let newDate2 = new Date(newDate.setDate(newDate.getDate()+dateKorrekt))

              const oneDate = new Date(sheetsDate)

              if (oneDate.getTime() > newDate2.getTime()) {

                val.isActive = false
              } else {
                if (infoAbn.dateBonusParty) {
                  if (val.balance > 0) {
                    const id = val.studentId
                    const value = val.numberSubscription
                    const quantity = val.quantity
                    const balanceNew = quantity - (val.balance - 1)
                    // console.log('teacher-dataFF', val, balanceNew)
                    dispatch(setStudentsNumPayType({id, value: 'абн'}))
                    dispatch(setStudentsNumPayValue({id, value}))
                    dispatch(setStudentsNumPayBalance({id, balanceNew}))
                    kontrol = true
                    break
                  }
                } else {
                  infoAbn.dateBonusParty = [sheetsDate]
                  const id = val.studentId
                  const value = val.numberSubscription
                  // console.log('teacher2-dataFF', val, value)
                  dispatch(setStudentsNumPayType({id, value: 'абн'}))
                  dispatch(setStudentsNumPayValue({id,value}))
                  dispatch(setStudentsNumPayBalance({id, balanceNew: 'бон'}))
                  kontrol = true
                  break
                }
              }
                
              } else {
                if (val.balance > 0) {
                  const id = val.studentId
                  const value = val.numberSubscription
                  const quantity = val.quantity
                  const balanceNew = quantity - (val.balance - 1)
                  // console.log('teacher3-dataFF', val, balanceNew)
                  dispatch(setStudentsNumPayType({id, value: 'абн'}))
                  dispatch(setStudentsNumPayValue({id, value}))
                  dispatch(setStudentsNumPayBalance({id, balanceNew}))
                  kontrol = true
                  break
                }
              }
            } else {
              if(val.isFire) {
                const infoAbn = JSON.parse(val.info)

                const oldDate = new Date(Date.parse(val.dateBegin))

                let dateKorrekt = 1
                if(process.env.REACT_APP_KORREKT_DATE) {
                  dateKorrekt = Number(process.env.REACT_APP_KORREKT_DATE)
                }

                let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
                let newDate2 = new Date(newDate.setDate(newDate.getDate()+dateKorrekt))


                const oneDate = new Date(sheetsDate)


                if (oneDate.getTime() > newDate2.getTime()) {

                  val.isActive = false
                } else {
                    if (val.balance > 0) {
                      const id = val.studentId
                      const value = val.numberSubscription
                      const quantity = val.quantity
                      const balanceNew = quantity - (val.balance - 1)
                      // console.log('teacher4-dataFF', val, balanceNew)
                      dispatch(setStudentsNumPayType({id, value: 'абн'}))
                      dispatch(setStudentsNumPayValue({id, value}))
                      dispatch(setStudentsNumPayBalance({id, balanceNew}))
                      kontrol = true
                      break
                    }
                }
                  
                } else {
                  if (val.balance > 0) {
                    const id = val.studentId
                    const value = val.numberSubscription
                    const quantity = val.quantity
                    const balanceNew = quantity - (val.balance - 1)
                    // console.log('teacher5-dataFF', val, balanceNew)
                    dispatch(setStudentsNumPayType({id, value: 'абн'}))
                    dispatch(setStudentsNumPayValue({id, value}))
                    dispatch(setStudentsNumPayBalance({id, balanceNew}))
                    kontrol = true
                    break
                  }
                }
            }
          }
          if (kontrol === false) {
            console.log('val339', num)
            console.log(stuSub[num])
            const id = stuSub[num].id
            // TODO: разная строчка между Teacher
            if(!studentsNumPay[`${id}-stuNumPay`] ||  studentsNumPay[`${id}-stuNumPay`].type != 'абн') {
            // if(!studentsNumPay[`${id}-stuNumPay`] ||  studentsNumPay[`${id}-stuNumPay`].type == 'абн') {
              dispatch(setStudentsNumPayType({id, value: 'абн'}))
              dispatch(setStudentsNumPayValue({id, value: 'нет!'}))
              dispatch(setStudentsNumPayBalance({id, balanceNew: ''}))
            }
          }

        // })


      } else {
        
        // const id = stuSub[num].id
        const id = num
        if(!studentsNumPay[`${id}-stuNumPay`] ||  studentsNumPay[`${id}-stuNumPay`].type == 'абн') {
          
          // console.log('teacher6-dataFF', id )
          dispatch(setStudentsNumPayType({id, value: 'абн'}))
          dispatch(setStudentsNumPayValue({id, value: 'нет'}))
          dispatch(setStudentsNumPayBalance({id, balanceNew: ''}))
        }
      }

    // }

  } else {
    dispatch(setStudentsNumPayType({id: val, value: 'абн'}))
    dispatch(setStudentsNumPayValue({id: val, value: 'нет!'}))
    dispatch(setStudentsNumPayBalance({id: val, balanceNew: ''}))
  }

	}



	const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		// console.log(event)
		const value = event.target.value;
		const id = parseInt(event.target.name);

		if(value === 'руч' || value === 'бонус') {
			(document.getElementById(`${id}-stuPayInput`) as HTMLInputElement).disabled = false;
		} else {
			(document.getElementById(`${id}-stuPayInput`) as HTMLInputElement).disabled = true;
		}
		const input = (document.getElementById(`${id}-stuPayInput`) as HTMLInputElement)
		// if (input)
		switch(value) {
			case 'нал':
				input.value = `${PAY_VALUE_DEFAULT}нал`;
				dispatch(setStudentsNumPayValue({id, value: `${PAY_VALUE_DEFAULT}нал`}))
				break
			case 'б/н':
				input.value = `${PAY_VALUE_DEFAULT}б/н`;
				dispatch(setStudentsNumPayValue({id, value:`${PAY_VALUE_DEFAULT}б/н`}))
				break
			case 'руч':
				input.value = ``;
				dispatch(setStudentsNumPayValue({id, value: ''}))
				break
			case 'абн':
				funSubscriptExp({id})
				// funSubscrib(id)
				// input.value = `нет`;
				// dispatch(setStudentsNumPayValue({id, value: 'нет'}))
				break
			default:
        input.value = value;
				dispatch(setStudentsNumPayValue({id, value: value}))
				break
		}

		dispatch(setStudentsNumPayType({id, value}))
    // setSelectedOption(value);
	}
	const onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		// console.log(event)
		const value = event.target.value;
		const id = parseInt(event.target.id);
		// console.log(value, id)
		dispatch(setStudentsNumPayValue({id, value}))
	} 

	// console.log('Row', name, nameTwo)

  const handleCheckPeople = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const id = parseInt(target.id);
    // if(studentsNumPay[`${id}-stuNumPay`]) {
    //   if(studentsNumPay[`${id}-stuNumPay`].check) {
    //     event.currentTarget.classList.remove('row__number_check')
    //   } else {
    //     event.currentTarget.classList.add('row__number_check')
    //   }
    // }
    dispatch(setStudentsNumPayCheck({id}))
  }
	return (
		<div className="row">
			<div className="row__container">
				<div className={studentsNumPay[`${student.number}-stuNumPay`].check ? 'row__number row__number_check' : 'row__number'} id={`${student.number}-stuNumber`} onClick={handleCheckPeople}>
					{ind}
				</div>
				<div className="row__name row__name_only">
					{nameTwo}
				</div>
				<div className="row__pay pay-row">
					<div className="pay-row__select">
						<select name={`${nameOne}-stuPayType`} value={tempType} onChange={onSelect} >
							<option value={`абн`}>абн</option> 
              { PayValue ? Object.keys(PayValue).map(key => {
                return (<>
                  <option value={PayValue[key as keyof typeof PayValue]}>{PayValue[key as keyof typeof PayValue]}</option>
                </>)
              }) : (<>
							<option value={`б/н`}>б/н</option>
							<option value={`нал`}>нал</option> 
              </>)}
							<option value={`руч`}>руч</option> 
						</select>
					</div>
					<div className="pay-row__input">
						<input id={`${nameOne}-stuPayInput`} disabled={true} onChange={onInput} />
					</div>
				</div>
        <div>
          {tempBalance}
        </div>
			</div>
		</div>
	);
}

export default RowOnlyStu;
