import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api, { signInNew } from '../../API';
import NavBar from '../../components/NavBar';
import { authorization } from '../../controller/userController';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setLoggedIn, setToken, setUser, setUsersR } from '../../redux/slices/userSlice';
import mainLogo1 from './../../img/logo_main_1.png';
import './SignUpStu.scss';
import { SubmitHandler, useForm } from "react-hook-form"
import Field from '../../components/Field/Field';
import { Lektors } from '../../utils/consts';
import { queryString } from '../../utils/queryString';

interface MainProps {
  title?: string;
}

export interface ISignUpForm {
  login: string
  name: string
  email: string
  phone: string
  date: string
  linkVk: string
  promo: string
}

const SignUpStu: FC<MainProps> = ({
  title= "Заявка на регистрацию"
}) => {

  const loggedIn = useAppSelector((state) => state.usersR.loggedIn)
  const token = useAppSelector((state) => state.usersR.token)
  const dispatch = useAppDispatch();

  const { register: formRegister, handleSubmit, formState: {errors} , reset, watch } = useForm<ISignUpForm>({ mode: "onChange" })


  const [sendDisable, setSendDisable] = useState(false);

  let navigate = useNavigate();


  const promoLektor: Record<string, string> = {}
  // Lektors.map((lektor: string, ind: number) => promoLektor[`la${ind+1}`] = lektor)
  Lektors.map((lektor: string, ind: number) => promoLektor[`lat${ind+1}`] = lektor)


  const titleM = title;
  const AuthApp = (data: ISignUpForm) => {

    setSendDisable(true)

    // const username = (document.querySelector('#login') as HTMLInputElement).value
    // const fio = (document.querySelector('#fio') as HTMLInputElement).value
    // const email = (document.querySelector('#email') as HTMLInputElement).value
    // const phone = (document.querySelector('#phone') as HTMLInputElement).value
    // const birthday = (document.querySelector('#birthday') as HTMLInputElement).value
    // const link_vk = (document.querySelector('#vk') as HTMLInputElement).value
    // const promo = (document.querySelector('#promo') as HTMLInputElement).value
    // const password = process.env.REACT_APP_DEFAULT_PASSWORD

    const queryParams = {
      login: data.login,
      name: data.name,
      email: data.email,
      phone: data.phone,
      date: data.date,
      linkVk: data.linkVk,
      promo: data.promo,
    }

    const dataSendLog = {
      login: process.env.REACT_APP_USER_ONE,
      message: `Заявка на регистрацию:
Логин: ${data.login}
ФИО: ${data.name}
E-mail: ${data.email}
Телефон: ${data.phone}
Дата рождения: ${data.date}
Профиль ВК: ${data.linkVk}
Промокод: ${data.promo}

Ссылка для регистрации: ${process.env.REACT_APP_DOM_SEND_2}/sign-up?${queryString(queryParams)}

`
    }

    // console.log(dataSend)

    const resLog = fetch(`${process.env.REACT_APP_DOM_SEND}/ipa/v2/bot-telegram/log-write`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataSendLog)
    })

    const dataSend = {
      login: process.env.REACT_APP_USER_ONE,
      message: `Заявка на регистрацию:
Логин: ${data.login}
ФИО: ${data.name}
E-mail: ${data.email}
Телефон: ${data.phone}
Дата рождения: ${data.date}
Профиль ВК: ${data.linkVk}
Промокод: ${data.promo}

Ссылка для регистрации: ${process.env.REACT_APP_DOM_SEND_2}/sign-up?${queryString(queryParams)}

`
    }

    // console.log(dataSend)

    const res = fetch(`${process.env.REACT_APP_DOM_SEND}/ipa/v2/bot-telegram`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataSend)
    })

    const dataSend2 = {
      login: process.env.REACT_APP_USER_TWO,
      message: `Заявка на регистрацию:
Логин: ${data.login}
ФИО: ${data.name}
E-mail: ${data.email}
Телефон: ${data.phone}
Дата рождения: ${data.date}
Профиль ВК: ${data.linkVk}
Промокод: ${data.promo}

Ссылка для регистрации: ${process.env.REACT_APP_DOM_SEND_2}/sign-up?${queryString(queryParams)}

`
    }


    const res2 = fetch(`${process.env.REACT_APP_DOM_SEND}/ipa/v2/bot-telegram`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataSend2)
    })


    const dataSend4 = {
      login: process.env.REACT_APP_USER_THREE,
      message: `Заявка на регистрацию:
Логин: ${data.login}
ФИО: ${data.name}
E-mail: ${data.email}
Телефон: ${data.phone}
Дата рождения: ${data.date}
Профиль ВК: ${data.linkVk}
Промокод: ${data.promo}

Ссылка для регистрации: ${process.env.REACT_APP_DOM_SEND_2}/sign-up?${queryString(queryParams)}

`
    }


    const res4 = fetch(`${process.env.REACT_APP_DOM_SEND}/ipa/v2/bot-telegram`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataSend4)
    })
    console.log(promoLektor[data.promo])

    const dataSend3 = {
      login: promoLektor[data.promo] ? promoLektor[data.promo] : '',
      message: `Заявка на регистрацию:
Логин: ${data.login}
ФИО: ${data.name}
E-mail: ${data.email}
Телефон: ${data.phone}
Дата рождения: ${data.date}
Профиль ВК: ${data.linkVk}
Промокод: ${data.promo}

Ссылка для регистрации: ${process.env.REACT_APP_DOM_SEND_2}/sign-up?${queryString(queryParams)}

`
    }


    const res3 = fetch(`${process.env.REACT_APP_DOM_SEND}/ipa/v2/bot-telegram`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataSend3)
    })

    setTimeout(() => {


      // const username = (document.querySelector('#login') as HTMLInputElement)
      // const fio = (document.querySelector('#fio') as HTMLInputElement)
      // const email = (document.querySelector('#email') as HTMLInputElement)
      // const phone = (document.querySelector('#phone') as HTMLInputElement)
      // const birthday = (document.querySelector('#birthday') as HTMLInputElement)
      // const link_vk = (document.querySelector('#vk') as HTMLInputElement)
      // const promo = (document.querySelector('#promo') as HTMLInputElement)


//       alert(
//         `Пользователь ${fio.value}, Ваша заявка отправлена.
// Как  только заявку одобрят, Вы сможете зайти используя следующие данные:
// Логин - ${username.value}
// Пароль - ${process.env.REACT_APP_DEFAULT_PASSWORD}
// `)
      alert(`Пользователь ${data.name}, Ваша заявка отправлена. Ждите ответа, в течение суток Вам ответит администратор`)

      reset()

      setSendDisable(false)

      // username.value = ''
      // fio.value = ''
      // email.value = ''
      // phone.value = ''
      // birthday.value = ''
      // link_vk.value = ''
      // promo.value = ''

      setSendDisable(false)
    }, 2000)


  }


  const AuthClose = () => {
    // onSignOut()
    navigate('/')
  }

  const validLogin = /^[a-zA-Z0-9_-]+$/
  const validName = /^[а-яА-ЯёЁ -]+$/
  const validEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const validPhone = /^[0-9]{11}$/
  const validDate = /^[0-9]{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}$/

  const onSubmit: SubmitHandler<ISignUpForm> = (data) => {
    // console.log(data)

    AuthApp(data)

    // reset()
  }

  return (
    <div className="Main">
      <div className='lat-form'>
        <div className='lat-form__container lat-form__container_shadow'>
          <NavBar />
          <img className="logo" src={mainLogo1} alt='логотип'></img>
          <div className='lat-form__box'>


            <div>
            {titleM}
            </div>
            {!loggedIn ?
              <div className='loging'>
                {/* <label htmlFor='login'>Логин</label>
                <div className='sign-up-primer'>Пример: ironman</div>
                <input id='login'></input>
                <label htmlFor='fio'>ФИО</label>
                <div className='sign-up-primer'>Пример: Иванов Иван Иваныч</div>
                <input id='fio'></input>
                <label htmlFor='email'>E-mail</label>
                <div className='sign-up-primer'>Пример: ironman@gmail.com</div>
                <input id='email'></input>
                <label htmlFor='phone'>Телефон</label>
                <div className='sign-up-primer'>Пример: 88001234567</div>
                <input id='phone'></input>
                <label htmlFor='birthday'>Дата рождения</label>
                <div className='sign-up-primer'>Пример: 2023-01-31</div>
                <input id='birthday'></input>
                <label htmlFor='vk'>Профиль ВК</label>
                <div className='sign-up-primer'>Пример: https://vk.com/id1</div>
                <input id='vk'></input>
                <label htmlFor='promo'>Промокод</label>
                <input id='promo'></input> */}

                <form onSubmit={handleSubmit(onSubmit)} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                  {/* <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: ironman'}
                  </div>
                  <div>
                    <input 
                    {...formRegister('login', {
                      required: 'login обязательное поле',
                      pattern: {
                        value: validName,
                        message: 'Допустимы кириллица, латиница, пробел, _ и -'
                      }
                    })}
                    placeholder='Логин'
                    // error={errors.name?.message}
                    />

                  </div>
                  { errors && errors.login && errors.login?.message ? 
                  (<>
                    <div style={{maxWidth: '177px', color: 'red'}}>
                      {errors.name?.message}
                    </div>
                  </>) 
                  : (<>
                    <div style={{minHeight: '42px'}}>
                      {' '}
                    </div>
                  </>)} */}


                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: ironman'}
                  </div>
                  <Field
                    {...formRegister('login', {
                      required: 'Логин обязательное поле',
                      pattern: {
                        value: validLogin,
                        message: 'Допустимы латиница, _ и -'
                      }
                    })}
                    placeholder='Логин'
                    error={errors.login?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: Иванов Иван Иваныч'}
                  </div>
                  <Field
                    {...formRegister('name', {
                      required: 'ФИО обязательное поле',
                      pattern: {
                        value: validName,
                        message: 'Допустимы кириллица, -, пробел'
                      }
                    })}
                    placeholder='ФИО'
                    error={errors.name?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: ironman@gmail.com'}
                  </div>
                  <Field
                    {...formRegister('email', {
                      required: 'Email обязательное поле',
                      pattern: {
                        value: validEmail,
                        message: 'Некорректный формат почты'
                      }
                    })}
                    placeholder='Почта'
                    error={errors.email?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: Пример: 88001234567'}
                  </div>
                  <Field
                    {...formRegister('phone', {
                      required: 'Телефон обязательное поле',
                      pattern: {
                        value: validPhone,
                        message: 'Допустимы только цифры'
                      }
                    })}
                    placeholder='Телефон'
                    error={errors.phone?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: 2023-01-31'}
                  </div>
                  <Field
                    {...formRegister('date', {
                      required: 'Дата рождения обязательное поле',
                      pattern: {
                        value: validDate,
                        message: 'Формат обязателен: ГГГГ-ММ-ДД'
                      }
                    })}
                    placeholder='Дата рождения'
                    error={errors.date?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Пример: https://vk.com/id1'}
                  </div>
                  <Field
                    {...formRegister('linkVk')}
                    placeholder='Профиль vk'
                    error={errors.linkVk?.message}
                  />

                  <div style={{minHeight: '16px', fontSize: '12px'}}>
                      {'Необязательное поле'}
                  </div>
                  <Field
                    {...formRegister('promo')}
                    placeholder='Дополнительное поле'
                    error={errors.promo?.message}
                  />


                  <div>
                    <button disabled={sendDisable} type='submit'>Подать заявку</button>

                  </div>
                </form>

                {/* <label htmlFor='password'>Пароль</label>
                <input id='password'></input> */}
                {/* <button disabled={sendDisable} onClick={AuthApp}>Подать заявку</button> */}
              </div>
              :
              <button onClick={AuthClose}>Войти</button>
            }
            <div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpStu;
