import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAbn, setActivSub, setArrSub, setBalance, setDateBonusParty, setDateEndSub, setDateLesson, setDateLessonFull, setIsFire, setNameStudent, setPrice, setPubDate, setQuantity, setStatus, setUserData } from "../../redux/slices/personalOfficeSlice/personalOfficeSlice";
import { setLoggedIn } from "../../redux/slices/userSlice";
import mainLogo from './../../img/logo_main.png';
import './PersonalOffice.scss';
import LatinaController from "../../API/LatinaController";

interface PersonalOfficeProps {
  
}

const PersonalOffice: FC<PersonalOfficeProps> = () => {

  // const idStudent = 47

  let navigate = useNavigate();

  const dispatch = useAppDispatch()
  const nameStudent = useAppSelector((state) => state.personalOffice.nameStudent)
  const subscrip = useAppSelector((state) => state.personalOffice.subscrip)
  const balance = useAppSelector((state) => state.personalOffice.balance)
  const pubDate = useAppSelector((state) => state.personalOffice.pubDate)
  const dateLesson = useAppSelector((state) => state.personalOffice.dateLesson)
  const dateBonusParty = useAppSelector((state) => state.personalOffice.dateBonusParty)
  const price = useAppSelector((state) => state.personalOffice.price)
  const isFire = useAppSelector((state) => state.personalOffice.isFire)
  const quantity = useAppSelector((state) => state.personalOffice.quantity)
  const dateLessonFull = useAppSelector((state) => state.personalOffice.dateLessonFull)
  const status = useAppSelector((state) => state.personalOffice.status)
  const arrSub = useAppSelector((state) => state.personalOffice.arrSub)
  const activSub = useAppSelector((state) => state.personalOffice.activSub)
  const dateEnd = useAppSelector((state) => state.personalOffice.dateEnd)
  const userData = useAppSelector((state) => state.personalOffice.userData)

  const idStudent = useAppSelector((state) => state.usersR.user.id)
  const token = useAppSelector((state) => state.usersR.token)

  const priceSub = process.env.REACT_APP_PRICE_SUB ? Number(process.env.REACT_APP_PRICE_SUB) : 0
  const priceFire = process.env.REACT_APP_PRICE_FIRE ? Number(process.env.REACT_APP_PRICE_FIRE) : 0
  const priceHoreo = process.env.REACT_APP_PRICE_HOREO ? Number(process.env.REACT_APP_PRICE_HOREO) : 0



  useEffect(() => {


    const dataSend = {
      id: Number(idStudent)
    }

    LatinaController.studentOne(dataSend)
    .then(res => {
      // console.log('res',res)

      const nameData = JSON.parse(res.info)
      // console.log(nameData)
      dispatch(setNameStudent(nameData.info))

      const arr = res.subscriptions.map((val:any) => {
        return val.id
      })

      dispatch(setArrSub(arr))
      dispatch(setAbn(arr.join(',')))

      const data = res.subscriptions[0]

      let statusTemp = true
      if (data.isFire) {
        const oldDate = new Date(Date.parse(data.dateBegin))

        let dateKorrekt = 0
        if(process.env.REACT_APP_KORREKT_DATE) {
          dateKorrekt = Number(process.env.REACT_APP_KORREKT_DATE)
        }

        let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
        let newDate2 = new Date(newDate.setDate(newDate.getDate()+dateKorrekt))
        
        dispatch(setDateEndSub(newDate2.toISOString()))

        const oneDate = new Date()

        if (oneDate.getTime() > newDate2.getTime()) {
          statusTemp = false
        }
        
      } else {
        const oldDate = new Date(Date.parse(data.dateBegin))

        let newDate =  new Date(oldDate.setFullYear(oldDate.getFullYear()+1))
        // let newDate2 = new Date(newDate.setDate(newDate.getDate()+0))
        
        dispatch(setDateEndSub(newDate.toISOString()))

        const oneDate = new Date()

        if (oneDate.getTime() > newDate.getTime()) {
          statusTemp = false
        }
      }

      if (data.status == false) {
        statusTemp = false
      }

      

      dispatch(setBalance(data.balance))
      dispatch(setStatus(statusTemp))
      dispatch(setPubDate(data.dateBegin))
      dispatch(setPrice(data.price))
      dispatch(setIsFire(data.isFire))
      dispatch(setQuantity(data.quantity))
      
      const nameData2 = JSON.parse(data.info)

      dispatch(setDateLessonFull(nameData2.lessons))
      // console.log(nameData)
      dispatch(setNameStudent(nameData2.info))
      if (nameData.date) {
        dispatch(setDateLesson(nameData2.date))
      }
      
      if(nameData2.dateBonusParty) {
        // console.log('dfgdf333',nameData.dateBonusParty)
        dispatch(setDateBonusParty(nameData2.dateBonusParty))

      } else {
        // console.log('dfgdf444',nameData.dateBonusParty)
        dispatch(setDateBonusParty([]))
      }
      
      const subObj: any = {}

      res.subscriptions.map((val:any) => {
        subObj[val.id] = val
      })

      res.subscriptions = subObj
      
      dispatch(setUserData(res))
      return res
    })

    /*

    const data = fetch(`http://${process.env.REACT_APP_DOM}/api/student/${idStudent}/`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Token ${token}`,
      },
    })
      .then((res) => {
        if (res.status >= 400) {
          dispatch(setLoggedIn(false));
          navigate('/')
        }
        return res.json()
      })
      .then((data) => {
        // console.log('data',data);
        const nameData = JSON.parse(data.info)
        // console.log(nameData)
        dispatch(setNameStudent(nameData.info))
        let arr =  data.subscrip.split(',')
        const reg = /^\d+$/

        arr = arr.map((val:string) => {

          if (!reg.test(val)) {
            val = val.slice(1)
          }
          return val
        }).reverse()


        // console.log('use',arr)
        dispatch(setArrSub(arr))
        dispatch(setAbn(arr.join(',')))
        return(arr[0])
      })
      .then((number) => {
        fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${number}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Token ${token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log('data2',data);
          let statusTemp = true
          if (data.price == 2500) {
            const oldDate = new Date(Date.parse(data['pub_date']))
  
            let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
            let newDate2 = new Date(newDate.setDate(newDate.getDate()+0))
            
            dispatch(setDateEndSub(newDate2.toISOString()))
  
            const oneDate = new Date()
  
            if (oneDate.getTime() > newDate2.getTime()) {
              statusTemp = false
            }
            
          } else {
            const oldDate = new Date(Date.parse(data['pub_date']))
  
            let newDate =  new Date(oldDate.setFullYear(oldDate.getFullYear()+1))
            // let newDate2 = new Date(newDate.setDate(newDate.getDate()+0))
            
            dispatch(setDateEndSub(newDate.toISOString()))
  
            const oneDate = new Date()
  
            if (oneDate.getTime() > newDate.getTime()) {
              statusTemp = false
            }
          }

          if (data.status == false) {
            statusTemp = false
          }

          

          dispatch(setBalance(data.balance))
          dispatch(setStatus(statusTemp))
          dispatch(setPubDate(data['pub_date']))
          dispatch(setPrice(data.price))
          dispatch(setQuantity(data.quantity))
          
          const nameData = JSON.parse(data.info)

          dispatch(setDateLessonFull(nameData.lessons))
          // console.log(nameData)
          dispatch(setNameStudent(nameData.info))
          if (nameData.date) {
            dispatch(setDateLesson(nameData.date))
          }
          
          if(nameData.dateBonusParty) {
            // console.log('dfgdf333',nameData.dateBonusParty)
            dispatch(setDateBonusParty(nameData.dateBonusParty))

          } else {
            // console.log('dfgdf444',nameData.dateBonusParty)
            dispatch(setDateBonusParty([]))
          }
        })
      })
      .catch((err) => console.log(err))
      .finally(() => {});

      // const dataT = fetch(`${process.env.REACT_APP_DOM}/api/subsconst/540/`,
      // {
      //   method: 'get',
      //   headers: {
      //     'Content-Type': 'application/json; charset=UTF-8',
      //     'Authorization': `Token ${token}`,
      //   },
      // })
      // .then((res) => res.json())
      // .then((data) => {
      //   console.log('data2',data);

      //   dispatch(setBalance(data.balance))
      //   dispatch(setStatus(data.status))
      //   dispatch(setPubDate(data['pub_date']))

      //   const nameData = JSON.parse(data.info)
      //   console.log(nameData)
      //   dispatch(setNameStudent(nameData.info))
      //   if (nameData.date) {
      //     dispatch(setDateLesson(nameData.date))
      //   }
      // })

      */
  }, []);


  const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {

    // const value = (event.target.value).slice(1);
    const value = (event.target.value);
    dispatch(setActivSub(Number(value)))

    // console.log('userData',userData)

    const res = userData


    // console.log('res',res)
    // const nameData = JSON.parse(res.info)
    // // console.log(nameData)
    // dispatch(setNameStudent(nameData.info))

    // const arr = res.subscriptions.map((val:any) => {
    //   return val.id
    // })

    // dispatch(setArrSub(arr))
    // dispatch(setAbn(arr.join(',')))


    const data = res.subscriptions[Number(value)]


    let statusTemp = true
    if (data.isFire) {
      const oldDate = new Date(Date.parse(data.dateBegin))

      let dateKorrekt = 0
      if(process.env.REACT_APP_KORREKT_DATE) {
        dateKorrekt = Number(process.env.REACT_APP_KORREKT_DATE)
      }

      let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
      let newDate2 = new Date(newDate.setDate(newDate.getDate()+dateKorrekt))
      
      dispatch(setDateEndSub(newDate2.toISOString()))

      const oneDate = new Date()

      if (oneDate.getTime() > newDate2.getTime()) {
        statusTemp = false
      }
      
    } else {
      const oldDate = new Date(Date.parse(data.dateBegin))

      let newDate =  new Date(oldDate.setFullYear(oldDate.getFullYear()+1))
      // let newDate2 = new Date(newDate.setDate(newDate.getDate()+0))
      
      dispatch(setDateEndSub(newDate.toISOString()))

      const oneDate = new Date()

      if (oneDate.getTime() > newDate.getTime()) {
        statusTemp = false
      }
    }

    if (data.status == false) {
      statusTemp = false
    }

    

    dispatch(setBalance(data.balance))
    dispatch(setStatus(statusTemp))
    dispatch(setPubDate(data.dateBegin))
    dispatch(setPrice(data.price))
    dispatch(setIsFire(data.isFire))
    dispatch(setQuantity(data.quantity))
    
    // const nameData2 = JSON.parse(data.info)

    // dispatch(setDateLessonFull(nameData2.lessons))
    // // console.log(nameData)
    // dispatch(setNameStudent(nameData2.info))
    // if (nameData.date) {
    //   dispatch(setDateLesson(nameData2.date))
    // }
    
    // if(nameData2.dateBonusParty) {
    //   // console.log('dfgdf333',nameData.dateBonusParty)
    //   dispatch(setDateBonusParty(nameData2.dateBonusParty))

    // } else {
    //   // console.log('dfgdf444',nameData.dateBonusParty)
    //   dispatch(setDateBonusParty([]))
    // }




    if(data.info) {
      const nameData = JSON.parse(data.info)

      dispatch(setDateLessonFull(nameData.lessons))
      // console.log(nameData)
      dispatch(setNameStudent(nameData.info))
      if (nameData.date) {
        dispatch(setDateLesson(nameData.date))
      } else {
        dispatch(setDateLesson(['']))
      }
      
      if(nameData.dateBonusParty) {
        // console.log('dfgdf',nameData.dateBonusParty)
        dispatch(setDateBonusParty(nameData.dateBonusParty))

      } else {
        // console.log('dfgdf222',nameData.dateBonusParty)
        dispatch(setDateBonusParty([]))
      }

    } else {
      dispatch(setDateLesson(['']))
    }






        /*


        fetch(`${process.env.REACT_APP_DOM}/api/subsconst/${value}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Token ${token}`,
        },
      })
        .then((res) => {
          if (res.status >= 400) {
            dispatch(setLoggedIn(false));
            navigate('/')
          }
          return res.json()
        })
        .then((data) => {
          // console.log('data2',data);
          let statusTemp = true
          if (data.price == 2500) {
            const oldDate = new Date(Date.parse(data['pub_date']))
  
            let newDate =  new Date(oldDate.setMonth(oldDate.getMonth()+1))
            let newDate2 = new Date(newDate.setDate(newDate.getDate()+0))
            
            dispatch(setDateEndSub(newDate2.toISOString()))
  
            const oneDate = new Date()
  
            if (oneDate.getTime() > newDate2.getTime()) {
              statusTemp = false
            }
            
          } else {
            const oldDate = new Date(Date.parse(data['pub_date']))
  
            let newDate =  new Date(oldDate.setFullYear(oldDate.getFullYear()+1))
            // let newDate2 = new Date(newDate.setDate(newDate.getDate()+0))
            
            dispatch(setDateEndSub(newDate.toISOString()))
  
            const oneDate = new Date()
  
            if (oneDate.getTime() > newDate.getTime()) {
              statusTemp = false
            }
          }

          if (data.status == false) {
            statusTemp = false
          }

          dispatch(setBalance(data.balance))
          dispatch(setStatus(statusTemp))
          dispatch(setPubDate(data['pub_date']))
          dispatch(setPrice(data.price))
          dispatch(setQuantity(data.quantity))
          

          if(data.info) {
            const nameData = JSON.parse(data.info)

            dispatch(setDateLessonFull(nameData.lessons))
            // console.log(nameData)
            dispatch(setNameStudent(nameData.info))
            if (nameData.date) {
              dispatch(setDateLesson(nameData.date))
            } else {
              dispatch(setDateLesson(['']))
            }
            
            if(nameData.dateBonusParty) {
              // console.log('dfgdf',nameData.dateBonusParty)
              dispatch(setDateBonusParty(nameData.dateBonusParty))
  
            } else {
              // console.log('dfgdf222',nameData.dateBonusParty)
              dispatch(setDateBonusParty([]))
            }

          } else {
            dispatch(setDateLesson(['']))
          }
        })

        */

  }



  return (
    <>
      <div className="List">
        <div className='lat-form'>
          <div className='lat-form__container lat-form__container_shadow'>
            <NavBar />
            <div className="lat-form__header header">
              <div className="header__left">
                
              </div>
              <div className="header__center">
                <img className="logo" src={mainLogo} alt='логотип'></img>
              </div>
              <div className="header__right">
                
              </div>
            </div>

            <div className='lat-form__office lat-office'>
              <div className="lat-office_title">
                <p>
                  Добро пожаловать в личный кабинет
                </p>
                <p style={{maxWidth: '300px', wordBreak: 'break-all'}}>
                  {nameStudent}
                </p>
              </div>
              <div className="lat-office_abn">
                <p>
                  Открытые абонементы:
                </p>
                <p style={{maxWidth: '300px', wordBreak: 'break-all'}}>
                  {subscrip}
                </p>
              </div>
              <div className="lat-office_abn">
                <p>
                  Выберите абонемент из списка:
                </p>

            <select className="select-personal" name={`activAbn`} value={activSub} onChange={onSelect} >
              {arrSub ? arrSub.map((it:any, ind:any) => <option className="select-personal-option" value={it} key={ind}>{it}</option>) : <option >{'старт'}</option>}
						</select>

              </div>

              <div className="lat-office_balance">
                <p>
                  Статус:
                </p>
                <p>
                  {status == true ? 'действующий' : 'не действует'}
                </p>
              </div>


              <div className="lat-office_balance">
                <p>
                  Кол-во оставшихся занятий:
                </p>
                <p>
                  {balance} из {quantity}
                </p>
              </div>
              <div className="lat-office_abn">
                <p>
                  Тип абонемента:
                </p>
                <p>
                  {isFire ? 'сгораемый' : 'годовой'}
                </p>
              </div>
              <div className="lat-office_date">
                <p>
                  Дата покупки:
                </p>
                <p>
                  {pubDate.substring(0,10)}
                </p>
              </div>
              <div className="lat-office_date">
                <p>
                  Дата окончания:
                </p>
                <p>
                  {dateEnd.substring(0,10)}
                </p>
              </div>


              { isFire ? 
                <>
                  <div className="lat-office_date">
                    <p>
                      Бонусная вечеринка:
                    </p>
                      {dateBonusParty.length > 0 ? dateBonusParty.map((it, ind) => <p key={ind}>{it.substring(0,10)}</p>) : '-'}
                  </div>
                </>
                : <></>
              }
              <div className="lat-office_lesson" style={{maxWidth: '300px', wordBreak: 'break-all'}}>
                <p>
                  Даты посещений:
                </p>
                  {dateLessonFull && dateLessonFull.length > 0 ? (
                      dateLessonFull.map((it, ind) => <p key={ind}>{it.date.substring(0,10)}{' '}{it.name}</p>)
                    ) : (
                      dateLesson.map((it, ind) => <p key={ind}>{it}</p>)
                      //{/* {dateLesson.map(it => <p>{`${new Date(Date.parse(it))}`}</p>)} */}
                      
                  )
                  }

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PersonalOffice;
