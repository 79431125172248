import { FC, useState } from "react"
import NavBar from "../../components/NavBar"
import mainLogo from './../../img/logo_main.png';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setDataStatCount, setDataStatSum, setDataTeachers, setLessonsInfo, setRender, setStatementInfo, setStatementList, setStatementList2, setStatementList3, setSubInfo, setinfoUserList } from "../../redux/slices/noticeInfoAdminSlice/noticeInfoAdminSlice";
import { ILessonFull, IStatementFull, ISubsconstFull } from "../../types/Interface";
import './NoticeInfoAdmin.scss'
import LatinaController from "../../API/LatinaController";
import Modal from "../../components/Modal/Modal";



export const NoticeInfoAdmin: FC = () => {
  const dispatch = useAppDispatch()
  const token = useAppSelector((state) => state.usersR.token)
  const statementInfo: any = useAppSelector((state) => state.noticeInfoAdmin.statementInfo)
  const lessonsInfo = useAppSelector((state) => state.noticeInfoAdmin.lessonsInfo)
  const render = useAppSelector((state) => state.noticeInfoAdmin.render)
  const statementList = useAppSelector((state) => state.noticeInfoAdmin.statementList)
  const statementList2 = useAppSelector((state) => state.noticeInfoAdmin.statementList2)
  const statementList3 = useAppSelector((state) => state.noticeInfoAdmin.statementList3)
  const infoUserList = useAppSelector((state) => state.noticeInfoAdmin.infoUserList)
  const dataStatCount = useAppSelector((state) => state.noticeInfoAdmin.dataStatCount)
  const dataStatSum = useAppSelector((state) => state.noticeInfoAdmin.dataStatSum)
  const dataTeachers = useAppSelector((state) => state.noticeInfoAdmin.dataTeachers)

  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState(0);
  const [modalText, setModalText] = useState('');

  const ArrStu:Array<any> = []
  
  const priceSub = process.env.REACT_APP_PRICE_SUB ? Number(process.env.REACT_APP_PRICE_SUB) : 0
  const priceFire = process.env.REACT_APP_PRICE_FIRE ? Number(process.env.REACT_APP_PRICE_FIRE) : 0
  const priceHoreo = process.env.REACT_APP_PRICE_HOREO ? Number(process.env.REACT_APP_PRICE_HOREO) : 0

  const onStatementInfo = () => {
    const number =  (document.querySelector('#number') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(number.toString())) {

      fetch(`${process.env.REACT_APP_DOM}/api/statement/${number}/`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          'Authorization': `Token ${token}`,
        },
        credentials: 'same-origin',
      })
      .then((res) => res.json())
      .then(async (dataStatement: IStatementFull) => {
        const infoStatement = JSON.parse(dataStatement.info)
        const directionArrName = infoStatement ? infoStatement.dir : []
        const coachArrName = infoStatement ? infoStatement.coa : []
        const dateStatement = dataStatement.pub_date.slice(0,10)
        const numberStatement = dataStatement.id

        const lessonsAll: ILessonFull[] = await lessons(numberStatement)
        dispatch(setLessonsInfo(lessonsAll))


        // console.log(lessonsAll)

        dispatch(setSubInfo([]))
        dispatch(setStatementInfo({directionArrName,coachArrName,dateStatement,numberStatement}))

        dispatch(setRender(1))

      })
      .catch(e => {
        console.log('sub', e)
        const directionArrName: any[] = []
        const coachArrName: any[] = []
        const dateStatement = 'ведомость несуществует'
        const numberStatement = number
        dispatch(setSubInfo([]))
        dispatch(setStatementInfo({directionArrName,coachArrName,dateStatement,numberStatement}))
        dispatch(setLessonsInfo([]))
        dispatch(setRender(1))
      })
    } else {
      dispatch(setSubInfo([]))
      dispatch(setStatementInfo({}))
      dispatch(setLessonsInfo([]))
    }

  }

  const listStatement = () => {
    
    // let elDom: HTMLElement = <div></div>


    // for (let keyState of Object.keys(statementInfo)) {
    //   elDom.append()
    // }


    return(
      <>
        <div>
          <span>Ведомость: </span>
          <span><b>{statementInfo.numberStatement}</b></span>
        </div>
        <div>
          <span>Дата заполнения: </span>
          <span><b>{statementInfo.dateStatement}</b></span>
        </div>
        <div>
          <div>Направление: </div>
          {statementInfo.directionArrName ? statementInfo.directionArrName.map((it:any, ind:any) => <div key={ind}><b>{it}</b></div>) : <></>}
        </div>
        <div>
          <div>Преподаватели: </div>
          {statementInfo.coachArrName ? statementInfo.coachArrName.map((it:any, ind:any) => <div key={ind}><b>{it}</b></div>) : <></>}
        </div>
        <div>
          <div>Ученики: </div>
          {lessonsInfo.length > 0 ? lessonsInfo.map((it:any, ind:any) => {
            const info = JSON.parse(it.info)
            return <>
              <div key={ind} className='notice-lesson-container'>
                <span><b>{ind + 1}</b></span>
                <span><b> {info.info} </b></span>
                <span><b> {it.value} </b></span>

              </div>
            </> 
          }) : <></>}
        </div>
    
      </>)
  }


  const lessons = async (numberStatement: number) => {
    const less = fetch(`${process.env.REACT_APP_DOM}/api/lesson/`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        'Authorization': `Token ${token}`,
      },
      credentials: 'same-origin',
    })
    .then((res) => res.json())
    .then((dataLessonAll: ILessonFull[]) => {
      return dataLessonAll.filter((el) => el.id_statement === numberStatement.toString())
    })
    return less
  }

  // const lessonsAll = async (numberArrStatement: Array<number>) => {
  //   const less = fetch(`http://${process.env.REACT_APP_DOM}/api/lesson/`, {
  //     method: 'GET',
  //     headers: {
  //       accept: 'application/json',
  //       'Content-Type': 'application/json; charset=UTF-8',
  //       'Authorization': `Token ${token}`,
  //     },
  //     credentials: 'same-origin',
  //   })
  //   .then((res) => res.json())
  //   .then((dataLessonAll: ILessonFull[]) => {
  //     return dataLessonAll.filter((el) => numberArrStatement.includes(Number(el.id_statement)))
  //   })
  //   return less
  // }



  const onStatementListInfo = async () => {

    // let arrStatement = []
    let arrStatement2 = []
    let lessAll2: Array<any> = []
    // let arrStatementNum: Array<number> = []
    let arrStatementNum2: Array<number> = []
    // let infoStatement:any = {}
    let infoStatement2:any = {}
    // let arrStatementObj: Record<string, IStatementFull> = {}
    let arrStatementObj2: Record<string, any> = {}

    const dataSendStat = {
      id: 1
    }

    let dataSubsconstAll2 = await LatinaController.statementLessonAll(dataSendStat)
    // console.log('dataSubsconstAll2', dataSubsconstAll2)
    
    // let dataSubsconstAll: Array<any> = await fetch(`http://${process.env.REACT_APP_DOM}/api/statement/`, {
    //   method: 'GET',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    //   credentials: 'same-origin',
    // })
    // .then(async (res) => {
    //   // если не найдено приходит 404, но при этом если не обработать то дальше прокидывается ответ и не уходит в catch
    //   if(res.status < 400){
    //     return res.json()
    //   }
    //   else {
    //     const err = await res.json()
    //     throw Error(JSON.stringify(err))
    //   }
    // })
    // .then(dataSubsconstAll => {
    //   console.log('dataSubsconstAll', dataSubsconstAll)
    //   return dataSubsconstAll
    // })
    // .catch(e => {
    //   console.log('sub', e)
    // })

    const month =  (document.querySelector('#month') as HTMLInputElement).value
    const year =  (document.querySelector('#year') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(month.toString())) {
      // console.log(dataSubsconstAll.slice(0,10))
      const mon = Number(month)
      let yearState = new Date().getFullYear()
      if(reg.test(year.toString())) {
        yearState = Number(year)
      }
      
      
      arrStatement2 = dataSubsconstAll2.filter((el:any) => {
        if(el.date) {
          const month = el.date.split('-')[1]
          const yearEl = el.date.split('-')[0]
          if(mon == Number(month) && yearState == Number(yearEl) && Number(el.number) !== 0) {
            arrStatementNum2.push(el.id)
            arrStatementObj2[el.id] = el
            lessAll2 = [...lessAll2, ...el.lesson]
            return true
          }
        }
      })
      
      // arrStatement = dataSubsconstAll.filter((el:any) => {
      //   if(el.pub_date_proba_pera) {
      //     const month = el.pub_date_proba_pera.split('-')[1]
      //     const yearEl = el.pub_date_proba_pera.split('-')[0]
      //     if(mon == Number(month) && yearState == Number(yearEl) && Number(el.number) !== 0) {
      //       arrStatementNum.push(el.id)
      //       arrStatementObj[el.id] = el
      //       return true
      //     }
      //   }
      // })
      // console.log('arr', arrStatement)
      // console.log(dataSubsconstAll)

      // dispatch(setStatementList(arrStatement2))
      // dispatch(setRender(3))

      
    } else {
      // dataSubsconstAll = []
      // dispatch(setStatementList(dataSubsconstAll))
      // dispatch(setRender(3))
    }
    
    // console.log(dataSubsconstAll)

    // const lessAll = await lessonsAll(arrStatementNum)

    // console.log('lessAll', lessAll)
    // console.log('lessAll2', lessAll2)
    // console.log('arrStatementObj2', arrStatementObj2)


    lessAll2.map((elLess:any) => {
      // console.log('elLess', elLess)
      if(Number(elLess.number) !== 0) {
        const info = JSON.parse(arrStatementObj2[elLess.statementList].info)
        if(!infoStatement2[elLess.statementList]) {
          infoStatement2[elLess.statementList] = {
            number: Number(elLess.statementList),
            date: arrStatementObj2[elLess.statementList].date.slice(0,10),
            nal: 0,
            beznal: 0,
            allMany: 0,
            abn: 0,
            handler: 0,
            handlerString: '',
            dir: info.dir.length > 1 ? info.dir[0] : info.dir[0],
            coach: info.coa.length > 1 ? `${info.coa[0]}; ${info.coa[1]}` : info.coa[0]
          }

        }

        
        if(elLess.payment === 'абн') {
          infoStatement2[elLess.statementList].abn = infoStatement2[elLess.statementList].abn + 1
        }

        if(elLess.payment === 'нал') {
          infoStatement2[elLess.statementList].nal = infoStatement2[elLess.statementList].nal + 400
          infoStatement2[elLess.statementList].allMany = infoStatement2[elLess.statementList].allMany + 400
        }
        
        if(elLess.payment === 'б/н') {
          infoStatement2[elLess.statementList].beznal = infoStatement2[elLess.statementList].beznal + 400
          infoStatement2[elLess.statementList].allMany = infoStatement2[elLess.statementList].allMany + 400
        }

        if(elLess.payment === 'руч') {
          infoStatement2[elLess.statementList].handler = infoStatement2[elLess.statementList].handler + 1
          infoStatement2[elLess.statementList].handlerString = infoStatement2[elLess.statementList].handlerString + '; ' + elLess.value
        }
      }
      

    })

    // lessAll.map((elLess:ILessonFull) => {
    //   if(Number(elLess.number) !== 0) {
    //     const info = JSON.parse(arrStatementObj[elLess.id_statement].info)
    //     if(!infoStatement[elLess.id_statement]) {
    //       infoStatement[elLess.id_statement] = {
    //         number: Number(elLess.id_statement),
    //         date: arrStatementObj[elLess.id_statement].pub_date.slice(0,10),
    //         nal: 0,
    //         beznal: 0,
    //         allMany: 0,
    //         abn: 0,
    //         handler: 0,
    //         handlerString: '',
    //         dir: info.dir.length > 1 ? info.dir[0] : info.dir[0],
    //         coach: info.coa.length > 1 ? `${info.coa[0]}; ${info.coa[1]}` : info.coa[0]
    //       }

    //     }

        
    //     if(elLess.paymet === 'абн') {
    //       infoStatement[elLess.id_statement].abn = infoStatement[elLess.id_statement].abn + 1
    //     }

    //     if(elLess.paymet === 'нал') {
    //       infoStatement[elLess.id_statement].nal = infoStatement[elLess.id_statement].nal + 400
    //       infoStatement[elLess.id_statement].allMany = infoStatement[elLess.id_statement].allMany + 400
    //     }
        
    //     if(elLess.paymet === 'б/н') {
    //       infoStatement[elLess.id_statement].beznal = infoStatement[elLess.id_statement].beznal + 400
    //       infoStatement[elLess.id_statement].allMany = infoStatement[elLess.id_statement].allMany + 400
    //     }

    //     if(elLess.paymet === 'руч') {
    //       infoStatement[elLess.id_statement].handler = infoStatement[elLess.id_statement].handler + 1
    //       infoStatement[elLess.id_statement].handlerString = infoStatement[elLess.id_statement].handlerString + '; ' + elLess.value
    //     }
    //   }
      

    // })

    // console.log('infoStatement', infoStatement)

    // let statementListArr = []
    let statementListArr2 = []

    for (let keyState of Object.keys(infoStatement2)) {
      statementListArr2.push(infoStatement2[keyState])
    }

    // for (let keyState of Object.keys(infoStatement)) {
    //   statementListArr.push(infoStatement[keyState])
    // }
    dispatch(setStatementList(statementListArr2))

    // console.log(statementListArr)
    // console.log(statementListArr2)
    // console.log(infoStatement2)

    dispatch(setStatementInfo(infoStatement2))
    dispatch(setRender(1))

  }

  const statementListItems = statementList?.map((item: any, index: any) => {

    return <div key={index} className='notice-lesson-container'>
      <div style={{maxWidth: '37px'}}>
        <span><b>{item.number}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.date}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.abn}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.nal}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.beznal}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.allMany}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.handler}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.dir}</b></span>
      </div>
      {/* <div style={{maxWidth: '90px'}}>
        <span><b>{item.coach}</b></span>
      </div> */}
    </div>
  })


  const onRender = () => {

    switch(render) {
      // case 0:  // if (x === 'value1')
      //   return <>
      //     <div className="sheets__item">
      //       <div className="sheets__item-text-info">
      //         {listItems}
      //       </div>
      //     </div>
      //   </>
      //   break
    
      case 1:  // if (x === 'value2')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
              {/* {statementInfo ? listStatement() : <></>} */}
              <div className='notice-lesson-container'>
                <div style={{maxWidth: '37px'}}>
                  <span>{'id'}</span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span>{'Дата'}</span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span>{'Абн'}</span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span>{'Нал'}</span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span>{'Б/н'}</span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span>{'Всего'}</span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span>{'Руч'}</span>
                </div>
                <div style={{maxWidth: '100px'}}>
                  <span>{'Направление'}</span>
                </div>
              </div>
              {statementListItems ? statementListItems : <></>}
            </div>
          </div>
        </>
        break

      case 2:
          return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
            <div className='notice-lesson-container'>
                <div style={{width: '55px'}}>
                  <span><b>{'Номер'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'ФИО'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'Дата'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'Инфо'}</b></span>
                </div>
              </div>
              {infoUserElem}
            </div>
          </div>
        </>
        break
    
      case 3:  // if (x === 'value2')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
            <div className='notice-lesson-container'>
                <div style={{width: '55px'}}>
                  <span><b>{'Номер'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'Дата'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'Направ.'}</b></span>
                </div>
                <div style={{maxWidth: '140px'}}>
                  <span><b>{'ФИО'}</b></span>
                </div>
              </div>
              {statementListItems}
            </div>
          </div>
        </>
        break

      case 4:  // if (x === 'value2')
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
              <div className='notice-lesson-container'>
                <div style={{width: '55px'}}>
                  <span><b>{'Нал'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{'Безнал'}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{`${priceFire}`}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{`${priceSub}`}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{'абн_другие'}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{'руч'}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{'хорео'}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{'Всего'}</b></span>
                </div>
                {/* <div style={{maxWidth: '90px'}}>
                  <span><b>{'Аренда'}</b></span>
                </div> */}
              </div>
              <div className='notice-lesson-container'>
                <div style={{width: '55px'}}>
                  <span><b>{dataStatCount.nal}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{dataStatCount.beznal}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{dataStatCount.fire}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatCount.year}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatCount.abn}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatCount.man}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatCount.horeo}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatCount.result}</b></span>
                </div>
                {/* <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatCount.rent}</b></span>
                </div> */}
              </div>
              <div className='notice-lesson-container'>
                <div style={{width: '55px'}}>
                  <span><b>{dataStatSum.nal}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{dataStatSum.beznal}</b></span>
                </div>
                <div style={{width: '90px'}}>
                  <span><b>{dataStatSum.fire}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatSum.year}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatSum.abn}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatSum.man}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatSum.horeo}</b></span>
                </div>
                <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatSum.result}</b></span>
                </div>
                {/* <div style={{maxWidth: '90px'}}>
                  <span><b>{dataStatSum.rent}</b></span>
                </div> */}
              </div>
              <div className='notice-lesson-container'>
                <div>
                  <span><b>{'Сумма зарплат'}</b></span>
                </div>
                <div>
                  <span><b>{dataTeachers.sumZpAll}</b></span>
                </div>
              </div>
              <div className='notice-lesson-container'>
                <div>
                  <span><b>{'Остаток'}</b></span>
                </div>
                <div>
                  <span><b>{Number(dataStatSum.result) - Number(dataTeachers.sumZpAll)}</b></span>
                </div>
              </div>
              <div className='notice-lesson-container'>
                <div>
                  <div>
                    {'Имя'}
                  </div>
                </div>
                <div>
                  <div>
                    {'Соло'}
                  </div>
                </div>
                <div>
                  <div>
                    {'Дуо'}
                  </div>
                </div>
                <div>
                  <div>
                    {'Сумма'}
                  </div>
                </div>
              </div>
              <>
                {dataTeachers && dataTeachers.name.map((el:any, ind: number) => {
                    return <>
                      <div className='notice-lesson-container' key={`${ind}-div1`}>
                        <div key={`${ind}-div2`}>

                            {el}

                        </div>
                        <div key={`${ind}-div3`}>

                            {dataTeachers.arrCountOne[ind]}

                        </div>
                        <div key={`${ind}-div4`}>

                            {dataTeachers.arrCountTwo[ind]}

                        </div>
                        <div key={`${ind}-div5`}>

                            {dataTeachers.arrSum[ind]}

                        </div>
                      </div>
                    </>
                })}
                <div className='notice-lesson-container'>
                  <div>
                    {'Номер'}
                  </div>
                  <div>
                    {'Абн'}
                  </div>
                  <div>
                    {'Нал'}
                  </div>
                  <div>
                    {'Безнал'}
                  </div>
                  <div>
                    {'Руч_цифры'}
                  </div>
                  <div>
                    {'Руч_всего'}
                  </div>
                  <div>
                    {'Бонус_вечер'}
                  </div>
                  <div>
                    {'Бонус_текст'}
                  </div>
                  <div>
                    {'Всего'}
                  </div>
                </div>
                {statementListItems2}
              </>

              {/* <div className='notice-lesson-container'>
                <div>
                  {'Номер'}
                </div>
                <div>
                  {'Дата'}
                </div>
                <div>
                  {'Имя'}
                </div>
                <div>
                  {'Аренда'}
                </div>
              </div>
              {statementListItems3} */}
            </div>
          </div>
        </>
        break
    
      default:
        return <>
          <div className="sheets__item">
            <div className="sheets__item-text-info">
              {statementListItems ? statementListItems : <></>}
            </div>
          </div>
        </>
        break
    }
  }

  const onLessonsInfo = async() => {
    let sum = 0
    let i = 0

    const dataSendStat = {
      id: 1
    }

    let subscriptionAll = await LatinaController.subscriptionAll(dataSendStat)

    // console.log('subscriptionAll', subscriptionAll)

    ;let arr = (subscriptionAll as Array<any>).filter((elFil:any) => {
      if (elFil.studentList != '1' && (elFil.isActive === true || elFil.balance > 0)) {
        return true
      }
    })
    arr = arr.filter((elArr:any) => {
      if(elArr.isActive === true && elArr.id > 600 && elArr.balance > 0) {
        return true
      }
    })
    .map((el:any) => {
      if(el.isFire) {
        const subDate = new Date(Date.parse(el.dateBegin))
        const from = new Date()
        let newDate =  new Date(subDate.setDate(subDate.getDate() + 41))
        // const to = new Date()
        // from.setDate(from.getDate() - 3)
        // to.setDate(to.getDate() + 1)
        if (from.getTime() < newDate.getTime()) {
          return el

          const infoAbn = JSON.parse(el.info)
          el.name = infoAbn.info
          let newDate2 =  new Date(subDate.setDate(subDate.getDate() + 10))
          el.dataEnd = newDate2.toISOString()
          ArrStu.push(el)
        } 
      } else {
        return el
      }

    })
    
    arr.map((elSubsconst:ISubsconstFull) => {
      if(elSubsconst && elSubsconst.id > 800) {
        const total = elSubsconst.price / elSubsconst.quantity * elSubsconst.balance
        sum = sum + total
        i = i + elSubsconst.balance
        
      }
      console.log(sum, i)
      // console.log(i)
    })

    // const less = fetch(`http://${process.env.REACT_APP_DOM}/api/subsconst/`, {
    //   method: 'GET',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    //   credentials: 'same-origin',
    // })
    // .then((res) => res.json())
    // .then((dataSubsconstAll: ISubsconstFull[]) => {

    //   ;let arr = (dataSubsconstAll as Array<any>).filter((elFil:any) => {
    //     if (elFil.id_users != '1' && (elFil.status === true || elFil.balance > 0)) {
    //       return true
    //     }
    //   })
    //   arr = arr.filter((elArr:any) => {
    //     if(elArr.status === true && elArr.id > 600 && elArr.balance > 0) {
    //       return true
    //     }
    //   })
    //   .map((el:any) => {
    //     if(el.price === 2500) {
    //       const subDate = new Date(Date.parse(el.pub_date))
    //       const from = new Date()
    //       let newDate =  new Date(subDate.setDate(subDate.getDate() + 41))
    //       // const to = new Date()
    //       // from.setDate(from.getDate() - 3)
    //       // to.setDate(to.getDate() + 1)
    //       if (from.getTime() < newDate.getTime()) {
    //         return el
  
    //         const infoAbn = JSON.parse(el.info)
    //         el.name = infoAbn.info
    //         let newDate2 =  new Date(subDate.setDate(subDate.getDate() + 10))
    //         el.dataEnd = newDate2.toISOString()
    //         ArrStu.push(el)
    //       } 
    //     } else {
    //       return el
    //     }

    //   })
      
    //   arr.map((elSubsconst:ISubsconstFull) => {
    //     if(elSubsconst && elSubsconst.id > 800) {
    //       const total = elSubsconst.price / elSubsconst.quantity * elSubsconst.balance
    //       sum = sum + total
    //       i = i + elSubsconst.balance
          
    //     }
    //     console.log(sum, i)
    //     // console.log(i)
    //   })
    // })
  }

  const totalStudent = async () => {
    const infoUser: any = {}
    const arrUniqueUser: Array<number> = []
    let lessAllArray: Array<ILessonFull> = []
    let statAllArray: Array<IStatementFull> = []

    const dataSendStat = {
      id: 1
    }

    let statementAll = await LatinaController.statementAll(dataSendStat)

    const dataSendStat2 = {
      id: 1
    }

    let lessonAll = await LatinaController.lessonAll(dataSendStat2)

    let statementAllObj: any = {}

    for (let elStatement of statementAll) {
      statementAllObj[elStatement.id] = elStatement
    }

    let lessonAllObj: any = {}
    for (let elLesson of lessonAll) {
      lessonAllObj[elLesson.id] = elLesson
    }

    // console.log(statementAllObj)
    // console.log(lessonAllObj)

    // const lessAllObj: Record<string,ILessonFull> = await fetch(`http://${process.env.REACT_APP_DOM}/api/lesson/`, {
    //   method: 'GET',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    // })
    // .then(res => res.json())
    // .then(arr => {
    //   lessAllArray = arr
    //   const obj: Record<string,ILessonFull> = {}
    //   arr.map((el: ILessonFull) => {
    //     obj[el.id] = el
    //   })
    //   return obj
    // })
    // const statAllObj: Record<string,IStatementFull> = await fetch(`http://${process.env.REACT_APP_DOM}/api/statement/`, {
    //   method: 'GET',
    //   headers: {
    //     accept: 'application/json',
    //     'Content-Type': 'application/json; charset=UTF-8',
    //     'Authorization': `Token ${token}`,
    //   },
    // })
    // .then(res => res.json())
    // .then(arr => {
    //   statAllArray = arr
    //   const obj: Record<string,IStatementFull> = {}
    //   arr.map((el: IStatementFull) => {
    //     obj[el.id] = el
    //   })
    //   return obj
    // })

    // console.log(lessAllArray)
    // console.log(statAllArray)

    for (let less of lessonAll ) {
    // for (let idLess of Object.keys(lessAllObj) ) {
      
      // if(!arrUniqueUser.includes(Number(lessAllObj[idLess].id_users)) && Number(lessAllObj[idLess].id_users) < 999) {
      //   arrUniqueUser.push(Number(lessAllObj[idLess].id_users))
      //   let info
      //   if(lessAllObj[idLess].info) {
      //     info = JSON.parse(lessAllObj[idLess].info)
      //   }
      //   infoUser[lessAllObj[idLess].id_users] = {
      //     info,
      //     date: statAllObj[lessAllObj[idLess].id_statement].pub_date.slice(0,10)
      //   }
      // }
      


      if(!arrUniqueUser.includes(Number(lessonAllObj[less.id].studentList)) && Number(lessonAllObj[less.id].studentList) < 999 && Number(lessonAllObj[less.id].studentList) > 30) {
        arrUniqueUser.push(Number(lessonAllObj[less.id].studentList))
        let info
        let infoDir
        if(lessonAllObj[less.id].info) {
          info = JSON.parse(lessonAllObj[less.id].info)
        }
        if(statementAllObj[lessonAllObj[less.id].statementList].info) {
          infoDir = JSON.parse(statementAllObj[lessonAllObj[less.id].statementList].info)
        }
        infoUser[lessonAllObj[less.id].studentList] = {
          info: info.info,
          date: statementAllObj[lessonAllObj[less.id].statementList].date.slice(0,10),
          directionUnique: [Number(statementAllObj[lessonAllObj[less.id].statementList].directionList)],
          // dirInfo: infoUser[lessAllObj[less.id].id_users].dirInfo ? [...infoUser[lessAllObj[less.id].id_users].dirInfo] : []
          dirInfo: [
            {
              idDir: Number(statementAllObj[lessonAllObj[less.id].statementList].directionList),
              dirName: infoDir.dir[0],
              date: statementAllObj[lessonAllObj[less.id].statementList].date.slice(0,10)
            }
          ]
        }
      } else {
        if(arrUniqueUser.includes(Number(lessonAllObj[less.id].studentList)) && infoUser[lessonAllObj[less.id].studentList] && !infoUser[lessonAllObj[less.id].studentList].directionUnique.includes(Number(statementAllObj[lessonAllObj[less.id].statementList].directionList))) {
          let infoDir
          if(statementAllObj[lessonAllObj[less.id].statementList].info) {
            infoDir = JSON.parse(statementAllObj[lessonAllObj[less.id].statementList].info)
          }
          infoUser[lessonAllObj[less.id].studentList].directionUnique.push(Number(statementAllObj[lessonAllObj[less.id].statementList].directionList))
          infoUser[lessonAllObj[less.id].studentList].dirInfo.push({ idDir: Number(statementAllObj[lessonAllObj[less.id].statementList].directionList), dirName: infoDir.dir[0], date: statementAllObj[lessonAllObj[less.id].statementList].date.slice(0,10)})
          
        }
      }
    }
    // console.log(arrUniqueUser)
    // console.log(infoUser)

    const arrUser = []
    for (let idUser of Object.keys(infoUser) ) {
      arrUser.push({id: idUser, ...infoUser[idUser]})

    }


    dispatch(setinfoUserList(arrUser))
    dispatch(setRender(2))

  }

  const infoUserElem = infoUserList?.map((item: any, index: any) => {


    return <div key={index} className='notice-lesson-container'>
      <div style={{maxWidth: '37px'}}>
        <span><b>{item.id}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.info}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.date}</b></span>
      </div>
      <div style={{maxWidth: '450px'}}>
        <span><b>{item.dirInfo.map((el: any, ind: number) => {
          return <span key={ind}>
            <span>{el.dirName}{' - '}</span>
            <span>{el.date}</span>
            <br />
          </span>
        })}</b></span>
      </div>
      {/* <div style={{maxWidth: '90px'}}>
        <span><b>{item.coach}</b></span>
      </div> */}
    </div>
  })

  const statZp = async () => {

    let dataStatZp

    const month =  (document.querySelector('#month') as HTMLInputElement).value
    const year =  (document.querySelector('#year') as HTMLInputElement).value
    const reg = /^\d+$/
    if(reg.test(month.toString())) {
      // console.log(dataSubsconstAll.slice(0,10))
      const mon = Number(month)
      let yearState = new Date().getFullYear()
      if(reg.test(year.toString())) {
        yearState = Number(year)
      }
  
      const dataSendStat = {
        id: 1,
        month: mon,
        year: yearState
      }
  
      dataStatZp = await LatinaController.statZp(dataSendStat)
  
    }


    dispatch(setStatementList2(dataStatZp.dataStatementArr))
    dispatch(setStatementList3(dataStatZp.dataStatementRentArr))

    dispatch(setDataStatCount(dataStatZp.dataStatCount))
    dispatch(setDataStatSum(dataStatZp.dataStatSum))
    dispatch(setDataTeachers(dataStatZp.dataTeachers))
    dispatch(setRender(4))


  }


  // const stat = async () => {

  //   const dataSendStat = {
  //     id: 1
  //   }

  //   let dataLessonAll = await LatinaController.dataStatAll(dataSendStat)

  //   // console.log(dataLessonAll)
  //   let arr: Array<any> = []
  //   let arrLessonNum: Array<any> = []
  //   let lessAll: Array<any> = []
  //   let arrLessonObj: any = {}
  //   let dataStatementObj: any = {}
  //   let dataStatementArr: Array<any> = []

  //   let dataStatCount = {
  //     nal: 0,
  //     beznal: 0,
  //     fire: 0,
  //     year: 0,
  //     abn: 0,
  //     man: 0,
  //     result: 0
  //   }
  //   let dataStatSum = {
  //     nal: 0,
  //     beznal: 0,
  //     fire: 0,
  //     year: 0,
  //     abn: 0,
  //     man: 0,
  //     result: 0
  //   }

  //   let arrName
  //   try {
  //     arrName = JSON.parse(process.env.REACT_APP_NOTICE_ARR_TEACHER || '')

  //   } catch (e) {
  //     console.log('JSON-parse', e)
  //   }

  //   let dataTeachers = {
  //     countOne: {} as any,
  //     countTwo: {} as any,
  //     sum: {} as any,
  //     sumZpAll: 0,
  //     arrCountOne: [] as any,
  //     arrCountTwo: [] as any,
  //     arrSum: [] as any,
  //     name: arrName
  //   }

  //   for (let i = 1; i < 32; i++) {
  //     dataTeachers.countOne[i] = 0
  //     dataTeachers.countTwo[i] = 0
  //     dataTeachers.sum[i] = 0
  //   }

  //   const month =  (document.querySelector('#month') as HTMLInputElement).value
  //   const year =  (document.querySelector('#year') as HTMLInputElement).value
  //   const reg = /^\d+$/
  //   if(reg.test(month.toString())) {
  //     // console.log(dataSubsconstAll.slice(0,10))
  //     const mon = Number(month)
  //     let yearState = new Date().getFullYear()
  //     if(reg.test(year.toString())) {
  //       yearState = Number(year)
  //     }
      

      
      
  //     arr = dataLessonAll.filter((el:any) => {
  //       if(el.statement) {
  //         const monthEl = el.statement.date.split('-')[1]
  //         const yearEl = el.statement.date.split('-')[0]
  //         if(mon == Number(monthEl) && yearState == Number(yearEl) && Number(el.number) !== 0 && el.statement.number !== 0 ) {
  //           arrLessonNum.push(el.id)
  //           arrLessonObj[el.id] = el
  //           lessAll = [...lessAll, el]

  //           if(!dataStatementObj[el.statement.id]) {
  //             dataStatementObj[el.statement.id] = {
  //               nal: 0,
  //               beznal: 0,
  //               fire: 0,
  //               year: 0,
  //               abn: 0,
  //               man: 0,
  //               result: 0,
  //               abnAll: 0,
  //               manAll: 0,
  //               bonus: 0,
  //               bonusTxt: 0
  //             }
  //           }

  //           if(el.payment === 'нал') {
  //             dataStatCount.nal++
  //             dataStatSum.nal = dataStatSum.nal + 400
  //             dataStatementObj[el.statement.id].nal++
  //           }
  //           if(el.payment === 'б/н') {
  //             dataStatCount.beznal++
  //             dataStatSum.beznal = dataStatSum.beznal + 400
  //             dataStatementObj[el.statement.id].beznal++
  //           }
  //           if(el.payment === 'руч' || el.payment === 'руч*') {
  //             dataStatementObj[el.statement.id].manAll++
  //             const reg = /^\d+$/
  //             if(reg.test(el.value)) {
  //               dataStatCount.man++
  //               dataStatSum.man = dataStatSum.man + Number(el.value)
  //               dataStatementObj[el.statement.id].man++
  //             }
  //             if(el.value.trim().toLowerCase() === 'бонус' || el.value.trim().toLowerCase() === 'приз') {
  //               dataStatementObj[el.statement.id].bonusTxt++
  //             }
  //           }
  //           if(el.payment === 'абн') {
  //             dataStatementObj[el.statement.id].abnAll++
  //             if(el.subscription.price === 3000) {
  //               dataStatSum.year = dataStatSum.year + 300
  //               dataStatCount.year++
  //               dataStatementObj[el.statement.id].year++
  //             }
  //             if(el.subscription.price === 2500) {
  //               dataStatSum.fire = dataStatSum.fire + 250
  //               dataStatCount.fire++
  //               dataStatementObj[el.statement.id].fire++
  //             }
  //             if(el.subscription.price !== 2500 && el.subscription.price !== 3000) {
  //               dataStatSum.abn = dataStatSum.abn + (el.subscription.price / el.subscription.quantity)
  //               dataStatCount.abn++
  //               dataStatementObj[el.statement.id].abn++
  //             }
  //           }
  //           if(el.payment === 'абн*') {
  //             dataStatementObj[el.statement.id].bonus++
  //           }



  //           if(el.statement.directionId !== 33 && el.statement.directionId !== 34 ) {
  //             if(el.statement.teachersList && el.statement.teachersList.split(',').length > 1) {
  //               dataTeachers.countTwo[el.statement.teachersList.split(',')[0]] = dataTeachers.countTwo[el.statement.teachersList.split(',')[0]] + 1
  //               dataTeachers.countTwo[el.statement.teachersList.split(',')[1]] = dataTeachers.countTwo[el.statement.teachersList.split(',')[1]] + 1
  //               dataTeachers.sum[el.statement.teachersList.split(',')[0]] = dataTeachers.sum[el.statement.teachersList.split(',')[0]] + 75
  //               dataTeachers.sum[el.statement.teachersList.split(',')[1]] = dataTeachers.sum[el.statement.teachersList.split(',')[1]] + 75
  //             } else {
  //               dataTeachers.countOne[el.statement.teachersList.split(',')[0]] = dataTeachers.countOne[el.statement.teachersList.split(',')[0]] + 1
  //               dataTeachers.sum[el.statement.teachersList.split(',')[0]] = dataTeachers.sum[el.statement.teachersList.split(',')[0]] + 113
  //             }
  //           } else {
  //             dataTeachers.countOne[31] = dataTeachers.countOne[31] + 1
  //           }

  //           return true
  //         }
  //       }
  //     })

  //   }
  //   dataStatCount.result = dataStatCount.nal + dataStatCount.beznal + dataStatCount.fire + dataStatCount.year + dataStatCount.abn + dataStatCount.man 
  //   dataStatSum.result = dataStatSum.nal + dataStatSum.beznal + dataStatSum.fire + dataStatSum.year + dataStatSum.abn + dataStatSum.man 



  //   for (let i = 1; i < 32; i++) {
  //     // dataTeachers.countOne[i] = 0
  //     // dataTeachers.countTwo[i] = 0
  //     // dataTeachers.sum[i] = 0

  //     dataTeachers.arrCountOne.push(dataTeachers.countOne[i])
  //     dataTeachers.arrCountTwo.push(dataTeachers.countTwo[i])
  //     dataTeachers.arrSum.push(dataTeachers.sum[i])

  //     dataTeachers.sumZpAll = dataTeachers.sumZpAll + dataTeachers.sum[i]
  //   }

  //   for (let val of Object.keys(dataStatementObj)) {
  //     // console.log('val', val)
  //     dataStatementObj[val].result = dataStatementObj[val].nal + dataStatementObj[val].beznal + dataStatementObj[val].fire + dataStatementObj[val].year + dataStatementObj[val].abn + dataStatementObj[val].man 
  //     dataStatementArr.push({id: val, ...dataStatementObj[val]})
  //   }


  //   // console.log('dataStatCount', dataStatCount)
  //   // console.log('dataStatSum', dataStatSum)
  //   // console.log('dataTeachers', dataTeachers)
  //   // console.log('dataStatementObj', dataStatementObj)
  //   // console.log('dataStatementArr', dataStatementArr)

  //   // let statementListArr2 = []

  //   // for (let keyState of Object.keys(infoStatement2)) {
  //   //   statementListArr2.push(infoStatement2[keyState])
  //   // }

  //   dispatch(setStatementList2(dataStatementArr))

  //   dispatch(setDataStatCount(dataStatCount))
  //   dispatch(setDataStatSum(dataStatSum))
  //   dispatch(setDataTeachers(dataTeachers))
  //   dispatch(setRender(4))

  // }

  const statementListItems2 = statementList2?.map((item: any, index: any) => {

    return <div key={index} className='notice-lesson-container'>
      <div style={{maxWidth: '37px'}}>
        <span><b>{item.id}</b></span>
      </div>
      {/* <div style={{maxWidth: '90px'}}>
        <span><b>{item.date}</b></span>
      </div> */}
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.abnAll}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.nal}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.beznal}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.man}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.manAll}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.bonus}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.bonusTxt}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.result}</b></span>
      </div>
      {/* <div style={{maxWidth: '90px'}}>
        <span><b>{item.dir}</b></span>
      </div> */}
      {/* <div style={{maxWidth: '90px'}}>
        <span><b>{item.coach}</b></span>
      </div> */}
    </div>
  })

  const statementListItems3 = statementList3?.map((item: any, index: any) => {

    return <div key={index} className='notice-lesson-container'>
      <div style={{maxWidth: '37px'}}>
        <span><b>{item.id}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.date}</b></span>
      </div>
      <div style={{maxWidth: '90px'}}>
        {item.name.map((thisName: any) => {
          return (<span><b>{`${thisName}, `}</b></span>)
        })}
      </div>
      <div style={{maxWidth: '90px'}}>
        <span><b>{item.rent}</b></span>
      </div>
    </div>
  })


  const onSendModalNo = () => {
    // console.log('modal-No', this)
    setModal(false)
  }

  const onSendModalYes = async () => {
    // console.log('modal-Yes', this)
    setModal(false)
    if(modalAction === 1) {
      // console.log('1')

      // const dataSendStat = {
      //   id: '1'
      // }

      // let dataSubsconstAll
      // dataSubsconstAll = await LatinaController.subscriptionTelegram(dataSendStat)

      // let str: string = ''
      // if(dataSubsconstAll.msg.length > 0) {
      //   for(let i=0; i < dataSubsconstAll.msg.length; i++) {
      //     str=str+dataSubsconstAll.msg[i]
      //   }
      // }
      // // console.log('console1', str.length)
      // // console.log('console2', dataSubsconstAll.str.length)
      // // dataSubsconstAll.str.length

      const res = fetch(`${process.env.REACT_APP_DOM_SEND}/ipa/v2/bot-telegram/service-subscribe-msg`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

    }
    if(modalAction === 2) {
      // console.log('2')

    }
    if(modalAction === 3) {
      // console.log('2')

    }
  }

  return (
    <>
      <div className="List">
        <div className='lat-form'>
          <div className='lat-form__container-info lat-form__container-info_shadow'>
            <NavBar />
            {/* <div className="lat-form__header header">
              <div className="header__left">

              </div>
              <div className="header__center">
                <img className="logo" src={mainLogo} alt='логотип'></img>
              </div>
              <div className="header__right">

              </div>
            </div> */}
            <div className="lat-form__sheets sheets">
              <div className="sheets__title">
                {`Информация доп`}
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text-info">
                  Месяц 
                  <span className='sign-up-primer'> (Только цифры)</span>
                </div>
                <input id='month' />
              </div>
              <div className="sheets__item">
                <div className="sheets__item-text-info">
                  Год 
                  <span className='sign-up-primer'> (Только цифры)</span>
                </div>
                <input id='year' />
              </div>
              <div className="sheets__item-admin-info">
                {onRender()}
              </div>
              <div className="sheets__send">
                <button onClick={() => {
                  setModalText(`Отправить данные в телеграмм?`)
                  setModalAction(1)
                  setModal(true)
                }}>
                  Абонементы ТГ
                </button>
                <button onClick={onStatementListInfo}>
                  Список вед.
                </button>
                <button onClick={onLessonsInfo}>
                  Инфо
                </button>
                <button onClick={totalStudent}>
                  Студенты
                </button>
                <button onClick={statZp}>
                  Статистика
                </button>
              </div>
            </div>


            <Modal isOpened={modal} onModalClose={() => {setModal(false)}} title={modalText} style={{}} >
                  <div className='btn-modal'>
                    <button className='btn-modal__elem' onClick={onSendModalYes}>
                      Да
                    </button>
                    <button className='btn-modal__elem' onClick={onSendModalNo}>
                      Нет
                    </button>
                    
                  </div>
            </Modal>


          </div>
        </div>
      </div>
    </>
  )
}
