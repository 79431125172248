export enum PayValue {
  '450б/н' = '450б/н',
  '450нал' = '450нал',
  '400б/н' = '400б/н',
  '400нал' = '400нал',
  '300б/н' = '300б/н',
  '300нал' = '300нал',
  'бонус' = 'бонус'
}
// export let PayValue: any

export const PAY_VALUE_DEFAULT = 450

