import React, { FC } from 'react';
import { useAppSelector } from '../redux/hooks';
import { IUserGet, IUserJSON } from '../types/Interface';
import { IStu } from '../types/Student';
import Row from './Row';
import './Lists.scss'

interface RowProps {
	//students?: IUserGet[] | null;
	students?: any;
	searchValue: string;
	onChangeSearchValue: (e: React.FormEvent<HTMLInputElement>) => void;
	onclickInvite?: any;
	invites:any;
	isLoading?: boolean;
  tagSearch?: any;
}

const Lists: FC<RowProps> = ({ 
	students, searchValue, onChangeSearchValue, onclickInvite, invites, isLoading, tagSearch
}) => {

  const directionNum = useAppSelector((state) => state.sheets.directionNum)
  const [rowFilter, setRowFilter] = React.useState(false)

  // const studentsNew = students?.map((val:any) => {
  //   console.log(val)
  //   let infoStu
  //   if (val){

  //     if(val.info) {
  //       infoStu = JSON.parse(val.info)
  //       console.log(infoStu)
  //       console.log(val)
  //     }
  //   }

  //   return {...val, info: infoStu}
  // })

  // console.log(studentsNew)
  // debugger
	const listItems = students?.filter((obj:any) => {
    if(tagSearch == 'students' || tagSearch == 'subscrip') {
      if(obj.id > 30) {
        // console.log(obj.direction)
        if (directionNum[0] && rowFilter) {
          // console.log(typeof directionNum[0].toString())
          // return true
          if(obj.directionsList) {
            if (directionNum.length == 3) {
              return obj.directionsList.split(',').includes(directionNum[0].toString()) || obj.direction.split(',').includes(directionNum[1].toString()) || obj.direction.split(',').includes(directionNum[2].toString())
            } else if (directionNum.length == 2) {
              return obj.directionsList.split(',').includes(directionNum[0].toString()) || obj.direction.split(',').includes(directionNum[1].toString())
            } else {
              return obj.directionsList.split(',').includes(directionNum[0].toString())
            }
          } else {
            return false
          }
        } else {
          return true
        }
      }
    } else {
      return true
    }
  }
  ).filter((obj:any) => {
		let fullName: any
    if (obj.info) {
      const infoStu = JSON.parse(obj.info)
      fullName = infoStu.info.toLowerCase()
		} else if (obj.name) {
			fullName = obj.name.toLowerCase()
		} else {
      fullName = ''
    }
		// if(obj.fio) {
		// 	fullName = obj.fio.toLowerCase()
		// } else if (obj.name) {
		// 	fullName = obj.name.toLowerCase()
		// }
		return fullName.includes(searchValue.toLowerCase());
	}).map((item: any) => {
	return <Row isInvited={invites.includes(Number(item["number"]))} onclickInvite={onclickInvite} student={item} key={item["number"]} />;
	}
	);

  const onChangeFilter = () => {
    setRowFilter(prev => !prev)
  }

	return (
		<>
			<div className="search">
				<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
					<path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
				</svg>
				<input value={searchValue} onChange={onChangeSearchValue} type="text" placeholder="Найти..." />
			</div>
      {tagSearch == 'students' ? (
        <div className="filter">
          <div>Фильтр</div>
          {/* <input type="checkbox" name="rowFilter" id={'rowFilter'} onChange={onChangeFilter} checked={rowFilter} /> */}
          <img className="action" onClick={() => onChangeFilter()} src={`/assets/${rowFilter ? 'minus' : 'plus'}.svg`} alt="Action" />
        </div>
      ) : (<></>)}
			{isLoading ? (
				<div className='Lists'>

				</div>
			) : (
			<div className='Lists'>
				{listItems}
			</div>
			)}
		</>
	)
}

export default Lists;
