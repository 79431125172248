import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface PersonalOfficeStateFull {
  subscrip: string,
  nameStudent: string,
  balance: number,
  status: boolean,
  dateLesson: Array<string>
  pubDate:string,
  price:number,
  isFire: boolean,
  quantity:number,
  dateLessonFull: Array<Record<string,string>>
  dateBonusParty: Array<string>,
  arrSub: any,
  activSub: number,
  dateEnd: string,
  userData: any
}

const initialState: PersonalOfficeStateFull = {
  subscrip: '',
  nameStudent: '',
  balance: 0,
  status: false,
  dateLesson: [],
  pubDate:'',
  price:0,
  isFire: false,
  quantity: 0,
  dateLessonFull: [],
  dateBonusParty: [],
  arrSub: [],
  activSub: 1,
  dateEnd: '',
  userData: {}
}

export const personalOfficeSlice = createSlice({
  name: 'personalOffice',
  initialState,
  reducers: {
    setAbn: (state, actions: PayloadAction<string>) => {
      state.subscrip = actions.payload
      const json = JSON.stringify(state.subscrip)
			localStorage.setItem('subscrip',json)
    },
    setNameStudent: (state, actions: PayloadAction<string>) => {
      state.nameStudent = actions.payload
      const json = JSON.stringify(state.nameStudent)
			localStorage.setItem('nameStudent',json)
    },
    setBalance: (state, actions: PayloadAction<number>) => {
      state.balance = actions.payload
      const json = JSON.stringify(state.balance)
			localStorage.setItem('balance',json)
    },
    setStatus: (state, actions: PayloadAction<boolean>) => {
      state.status = actions.payload
      const json = JSON.stringify(state.status)
			localStorage.setItem('status',json)
    },
    setDateLesson: (state, actions: PayloadAction<Array<string>>) => {
      state.dateLesson = actions.payload
      const json = JSON.stringify(state.dateLesson)
			localStorage.setItem('dateLesson',json)
    },
    setPubDate: (state, actions: PayloadAction<string>) => {
      state.pubDate = actions.payload
      const json = JSON.stringify(state.pubDate)
			localStorage.setItem('pubDate',json)
    },
    setPrice: (state, actions: PayloadAction<number>) => {
      state.price = actions.payload
      const json = JSON.stringify(state.price)
			localStorage.setItem('price',json)
    },
    setIsFire: (state, actions: PayloadAction<boolean>) => {
      state.isFire = actions.payload
      const json = JSON.stringify(state.isFire)
			localStorage.setItem('isFire',json)
    },
    setQuantity: (state, actions: PayloadAction<number>) => {
      state.quantity = actions.payload
      const json = JSON.stringify(state.quantity)
			localStorage.setItem('quantity',json)
    },
    setDateLessonFull: (state, actions: PayloadAction<Array<Record<string,string>>>) => {
      state.dateLessonFull = actions.payload
      const json = JSON.stringify(state.dateLessonFull)
			localStorage.setItem('dateLessonFull',json)
    },
    setDateBonusParty: (state, actions: PayloadAction<Array<string>>) => {
      state.dateBonusParty = actions.payload
      const json = JSON.stringify(state.dateBonusParty)
			localStorage.setItem('dateBonusParty',json)
    },
    setArrSub: (state, actions: PayloadAction<Array<string>>) => {
      state.arrSub = actions.payload
      const json = JSON.stringify(state.arrSub)
			localStorage.setItem('arrSub',json)
    },
    setActivSub: (state, actions: PayloadAction<number>) => {
      state.activSub = actions.payload
      const json = JSON.stringify(state.activSub)
			localStorage.setItem('activSub',json)
    },
    setDateEndSub: (state, actions: PayloadAction<string>) => {
      state.dateEnd = actions.payload
      const json = JSON.stringify(state.dateEnd)
			localStorage.setItem('dateEndSub',json)
    },
    setUserData: (state, actions: PayloadAction<any>) => {
      state.userData = actions.payload
      const json = JSON.stringify(state.userData)
			localStorage.setItem('userData',json)
    }
  },
})

export const {
  setAbn,
  setNameStudent,
  setBalance,
  setStatus,
  setDateLesson,
  setPubDate,
  setPrice,
  setIsFire,
  setQuantity,
  setDateLessonFull,
  setDateBonusParty,
  setArrSub,
  setActivSub,
  setDateEndSub,
  setUserData,
} = personalOfficeSlice.actions

export default personalOfficeSlice.reducer
